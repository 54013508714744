/* eslint-disable react/forbid-prop-types */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Helmet } from "react-helmet";
import { Header, Segment, Dropdown, Loader } from "semantic-ui-react";
import SubLineItemSearchContainer from "builder_portal/components/roomBookSearch/SubLineItemSearchContainer";
import { ProjectCatalogShape } from "shared/shapes/projectCatalog.shape";
import {
  getAccount,
  getCatalogsForProject,
  getProject
} from "shared/selectors";
import UnitTypesResource from "builder_portal/actions/unitTypesActions";
import ProjectAttachmentsList from "builder_portal/components/attachment/ProjectAttachmentsList";
import { If } from "shared/components/elements/Conditions";
import OptionsShape from "shared/shapes/options.shape";
import { browserHistory } from "shared/routes/browserHistory";
import UnitProspectLoader from "builder_portal/components/project/unitProspect/UnitProspectLoader";
import KanbanBoard from "builder_portal/components/project/unitProspect/kanbanBoard/KanbanBoard";
import { ProjectFeaturesResource } from "builder_portal/actions/projectFeaturesActions";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import TabMenu from "./TabMenu";
import Dashboard from "./Dashboard";
import ActivityList from "./activities/ActivityList";
import SectionAndUnit from "./sectionAndUnit/SectionAndUnit";
import RoomBookTemplateList from "./RoomBookTemplateList";
import StatisticsTab from "./StatisticsTab";
import DeadlinesTab from "./DeadlinesTab";
import ProjectContractorsCollection from "../contractor/ProjectContractors";
import "./projectItem.scss";
import ProjectBuyerContainer from "../buyer/ProjectBuyerContainer";
import { ProjectsResource } from "../../actions/projectActions";
import ProjectBuyersResource from "../../actions/buyerActions";
import CatalogResource from "../../actions/catalogActions";
import { UnitsResource } from "../../actions/unitActions";
import { ProjectRoomBooksResource } from "../../actions/roomBookActions";
import UpgradeBundlesResource from "../../actions/upgradeBundlesActions";
import { UnitVariableTypesResource } from "../../actions/unitVariablesActions";
import UserConfig from "../../../shared/actions/configActions";
import toggleDialogCreator from "../../helpers/toggleDialogCreator";
import * as ActivityResource from "../../actions/activityActions";
import {
  ProjectDeadlinesResource,
  SectionDeadlinesResource,
  UnitDeadlinesResource
} from "../../actions/projectDeadlinesActions";
import { AccountResource } from "../../actions/accountActions";
import UpgradeBundleGroupsResource from "../../actions/upgradeBundleGroupsActions";
import getUpgradeBundles from "../../selectors/upgradeBundles";
import getUpgradeBundleGroups from "../../selectors/upgradeBundleGroups";
import UpgradeBundleShape from "../../../shared/shapes/upgradeBundles.shape";
import UpgradeBundleGroupShape from "../../../shared/shapes/upgradeBundleGroup.shape";
import UnitSetup from "./UnitSetup";
import WizardContainer from "./projectWizard/WizardContainer";
import ProjectQuantities from "./projectQuantities/ProjectQuantities";

const TABS = [
  "overview",
  "room_books",
  "unit_setup",
  "sectionAndUnit",
  "documents",
  "project_setup",
  "activity",
  "deadlines",
  "buyers",
  "contractors",
  "statistics",
  "search",
  "project_quantities",
  "prospects"
];

const perspectiveConfig = {
  perspectives: [
    {
      id: "onboarding",
      title: "project.perspectives.onboarding",
      tabs: [
        "overview",
        "room_books",
        "unit_setup",
        "sectionAndUnit",
        "documents",
        "project_setup"
      ],
      hasProjectAccessRight: "allow_perspective_onboarding"
    },
    {
      id: "customer_management",
      title: "project.perspectives.customer_management",
      tabs: [
        "overview",
        "sectionAndUnit",
        "activity",
        "deadlines",
        "buyers",
        "documents",
        "contractors",
        "statistics",
        "search",
        "project_quantities"
      ]
    },
    {
      id: "sales",
      title: "project.perspectives.sales",
      tabs: ["overview", "prospects", "buyers", "sectionAndUnit", "documents"],
      hasProjectAccessRight: "allow_perspective_sales",
      featureFlag: "mp_prospect_generation"
    }
  ]
};

const getPerspectiveOptions = (account, projectId, i18n) => {
  const options = [];
  perspectiveConfig.perspectives.forEach(p => {
    if (
      p.hasProjectAccessRight &&
      !account.hasProjectRoleRight(projectId, p.hasProjectAccessRight)
    )
      return;

    if (p.featureFlag && !account.isEnabled(p.featureFlag)) return;

    options.push({
      key: p.id,
      value: p.id,
      text: i18n[p.title]
    });
  });
  return options;
};

const getActivePerspective = (projectSlug, tab) => {
  let activePerspective = "customer_management";
  try {
    const activePerspectives = JSON.parse(
      localStorage.getItem("activePerspectives")
    );
    if (activePerspectives[projectSlug])
      activePerspective = activePerspectives[projectSlug];
  } catch (e) {
    // skip getting
  }

  if (tab) {
    const config = perspectiveConfig.perspectives.find(
      x => x.id === activePerspective
    );
    if (config.tabs.indexOf(tab) > -1) return activePerspective;

    const perspectivByUrl = perspectiveConfig.perspectives.find(
      x => x.tabs.indexOf(tab) > -1
    );
    if (perspectivByUrl) return perspectivByUrl.id;
  }

  return activePerspective;
};

const setActivePerspective = (projectSlug, value) => {
  try {
    const activePerspectives = JSON.parse(
      localStorage.getItem("activePerspectives")
    );
    localStorage.setItem(
      "activePerspectives",
      JSON.stringify({ ...activePerspectives, [projectSlug]: value })
    );
  } catch (e) {
    // skip setting
  }
};

const permittedTabs = (visibleTabs, account, projectId) => {
  const temp = [...visibleTabs];
  if (
    temp.indexOf("deadlines") > -1 &&
    !account.hasProjectRoleRight(projectId, "allow_access_deadlines")
  )
    temp.splice(temp.indexOf("deadlines"), 1);
  if (
    temp.indexOf("documents") > -1 &&
    !account.hasProjectRoleRight(projectId, "allow_access_documents")
  )
    temp.splice(temp.indexOf("documents"), 1);
  if (
    temp.indexOf("statistics") > -1 &&
    !account.hasProjectRoleRight(projectId, "allow_access_statistics")
  )
    temp.splice(temp.indexOf("statistics"), 1);
  if (
    temp.indexOf("activity") > -1 &&
    !account.hasProjectRoleRight(projectId, "allow_access_activities")
  )
    temp.splice(temp.indexOf("activity"), 1);
  if (
    temp.indexOf("buyers") > -1 &&
    !account.hasProjectRoleRight(projectId, "allow_access_buyers")
  )
    temp.splice(temp.indexOf("buyers"), 1);
  if (
    temp.indexOf("contractors") > -1 &&
    !account.hasProjectRoleRight(projectId, "allow_access_contractors")
  )
    temp.splice(temp.indexOf("contractors"), 1);
  return temp;
};

const onlyOnePerspective = (account, projectId) => {
  if (
    !account.hasProjectRoleRight(projectId, "allow_perspective_onboarding") &&
    (!account.hasProjectRoleRight(projectId, "allow_perspective_sales") ||
      !account.isEnabled("mp_prospect_generation"))
  )
    return true;

  return false;
};

class ProjectItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePerspective: undefined,
      visibleTabs: []
    };
  }

  componentDidMount() {
    const {
      unitTypesResource,
      projectFeaturesResource,
      projectResource,
      id
    } = this.props;
    unitTypesResource.fetchAll();
    projectFeaturesResource.fetchAll();
    projectResource.fetchCustomImportConfigs(id);
    this.handleUpdate();
  }

  componentDidUpdate() {
    const { project, account, routeParams } = this.props;
    const { tab } = routeParams;
    const { activePerspective } = this.state;
    if (!project) return;

    let storedPerspective = getActivePerspective(project?.slug, tab);

    if (
      storedPerspective === "sales" &&
      !account.hasProjectRoleRight(project.id, "allow_perspective_sales")
    )
      storedPerspective = "customer_management";
    if (
      storedPerspective === "onboarding" &&
      !account.hasProjectRoleRight(project.id, "allow_perspective_onboarding")
    )
      storedPerspective = "customer_management";

    if (activePerspective !== storedPerspective) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.handleChangePerspective(null, { value: storedPerspective });
    }
  }

  handleChangePerspective = (e, { value }) => {
    const { project, account, activeTab } = this.props;

    const visibleTabs =
      perspectiveConfig.perspectives.find(p => p.id === value)?.tabs || [];

    if (visibleTabs.indexOf(activeTab) === -1) {
      browserHistory.push(`/projects/${project?.slug}`);
    } else {
      this.setState({
        activePerspective: value,
        visibleTabs: permittedTabs(visibleTabs, account, project.id)
      });
    }

    setActivePerspective(project.slug, value);
  };

  handleUpdate = () => {
    const { id, projectResource, catalogsResource } = this.props;
    projectResource.get(id);
    catalogsResource.fetchAll();
  };

  renderStatisticsTab() {
    const { id, activeTab } = this.props;

    if (activeTab === "statistics") {
      return <StatisticsTab projectId={id} />;
    }
    return null;
  }

  renderContent = () => {
    const {
      id,
      i18n,
      account,
      pageContent,
      activeTab,
      projectResource,
      buyersResource,
      unitsResource,
      projectRoomBooksResource,
      upgradeBundles,
      upgradeBundleGroups,
      upgradeBundlesResource,
      upgradeBundleGroupsResource,
      actions,
      userConfig,
      catalogs,
      unitVariableTypesResource,
      project,
      subTab
    } = this.props;

    const deadlines = get(pageContent, "deadlines", {});

    return (
      <Segment attached="bottom">
        {activeTab === "overview" && (
          <Dashboard
            account={account}
            i18n={i18n}
            project={project}
            statistics={get(pageContent, "projectStatistics")}
            projectResource={projectResource}
          />
        )}

        {activeTab === "project_setup" && <WizardContainer />}

        {activeTab === "activity" && <ActivityList id={id} />}

        {activeTab === "deadlines" && (
          <DeadlinesTab
            i18n={i18n}
            account={account}
            project={project}
            actions={actions}
            deadlines={deadlines}
            onUpdate={() => this.handleUpdate()}
          />
        )}

        {activeTab === "sectionAndUnit" && (
          <SectionAndUnit
            project={project}
            projectResource={projectResource}
            buyersResource={buyersResource}
            unitsResource={unitsResource}
            userConfig={userConfig}
          />
        )}

        {activeTab === "documents" && (
          <ProjectAttachmentsList projectId={project.id} />
        )}

        {activeTab === "room_books" && (
          <RoomBookTemplateList
            i18n={i18n}
            projectId={project.slug}
            roomBooks={get(pageContent, "room_books")}
            catalogs={catalogs}
            upgradeBundles={upgradeBundles}
            upgradeBundleGroups={upgradeBundleGroups}
            resources={{
              projectRoomBooks: projectRoomBooksResource,
              upgradeBundles: upgradeBundlesResource,
              upgradeBundleGroups: upgradeBundleGroupsResource,
              unitVariableTypes: unitVariableTypesResource
            }}
            currentTab={subTab}
          />
        )}

        {activeTab === "search" && (
          <SubLineItemSearchContainer projectId={project.slug} />
        )}

        {activeTab === "buyers" && (
          <ProjectBuyerContainer projectId={project.slug} />
        )}

        {activeTab === "prospects" && (
          <UnitProspectLoader projectId={project.slug} isProjectLevel>
            <KanbanBoard />
          </UnitProspectLoader>
        )}

        {this.renderStatisticsTab()}

        {activeTab === "contractors" && <ProjectContractorsCollection />}

        {activeTab === "unit_setup" && (
          <UnitSetup projectId={project.slug} currentTab={subTab} />
        )}
        {activeTab === "project_quantities" && (
          <ProjectQuantities projectId={project.slug} />
        )}
      </Segment>
    );
  };

  render() {
    const {
      i18n,
      account,
      pageContent,
      activeTab,
      subTab,
      perspectiveOptions,
      project
    } = this.props;
    const { activePerspective, visibleTabs } = this.state;

    const effectiveVisibleTabs = account.isEnabled("show_page_layout_v2")
      ? TABS
      : visibleTabs;

    const menuContentStyle = account.isEnabled("show_page_layout_v2")
      ? { display: "flex", gap: "1.5em" }
      : {};

    const pageStyle = account.isEnabled("show_page_layout_v2")
      ? { marginTop: "-0.6em" }
      : {};

    const responsiveClassName = account.isEnabled("show_page_layout_v2")
      ? "responsiveDirection"
      : "";

    if (!project) return <Loader active />;

    const pageName = project.name
      ? [project.name, i18n["meta.app.name"]].join(" - ")
      : [i18n["project.title.one"], i18n["meta.app.name"]].join(" - ");

    const { project_setup } = project;

    const showProjectSetup = !!project_setup;

    return (
      <div data-component="project" style={pageStyle}>
        <Helmet title={pageName} />

        <div className="flex justify-content-space-between align-items-center">
          <If condition={!account.isEnabled("show_page_layout_v2")}>
            <div className="flex justify-content-space-between">
              <Header size="large">{project.name}</Header>
            </div>
          </If>
          <FeatureToggle featureToggleName="show_page_layout_v2" disabled>
            <If condition={!onlyOnePerspective(account, project.id)}>
              <Dropdown
                id="perspectivesDropdown"
                options={perspectiveOptions}
                selection
                value={activePerspective}
                style={{ float: "right" }}
                onChange={this.handleChangePerspective}
              />
            </If>
          </FeatureToggle>
        </div>
        <div style={menuContentStyle} className={responsiveClassName}>
          <TabMenu
            i18n={i18n}
            statistics={get(pageContent, "project.statistics")}
            activeTab={activeTab}
            subTab={subTab}
            rootUrl={`/projects/${project.slug}`}
            perspectiveUrl={`perspective/${activePerspective}`}
            account={account}
            visibleTabs={effectiveVisibleTabs}
            showProjectSetup={showProjectSetup}
            projectName={project.name}
          />

          {this.renderContent()}
        </div>
      </div>
    );
  }
}

ProjectItem.propTypes = {
  id: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  project: PropTypes.object,
  catalogs: PropTypes.arrayOf(ProjectCatalogShape),
  pageContent: PropTypes.object,
  activeTab: PropTypes.string.isRequired,
  subTab: PropTypes.string,
  projectResource: PropTypes.instanceOf(ProjectsResource).isRequired,
  buyersResource: PropTypes.instanceOf(ProjectBuyersResource).isRequired,
  unitsResource: PropTypes.instanceOf(UnitsResource).isRequired,
  unitTypesResource: PropTypes.instanceOf(UnitTypesResource).isRequired,
  projectFeaturesResource: PropTypes.instanceOf(ProjectFeaturesResource)
    .isRequired,
  projectRoomBooksResource: PropTypes.instanceOf(ProjectRoomBooksResource)
    .isRequired,
  upgradeBundlesResource: PropTypes.instanceOf(UpgradeBundlesResource)
    .isRequired,
  upgradeBundleGroupsResource: PropTypes.instanceOf(UpgradeBundleGroupsResource)
    .isRequired,
  catalogsResource: PropTypes.instanceOf(CatalogResource).isRequired,
  actions: PropTypes.object.isRequired,
  userConfig: PropTypes.object.isRequired,
  upgradeBundles: PropTypes.arrayOf(UpgradeBundleShape).isRequired,
  upgradeBundleGroups: PropTypes.arrayOf(UpgradeBundleGroupShape).isRequired,
  unitVariableTypesResource: PropTypes.instanceOf(UnitVariableTypesResource)
    .isRequired,
  perspectiveOptions: PropTypes.arrayOf(OptionsShape).isRequired,
  routeParams: PropTypes.shape({
    tab: PropTypes.string
  }).isRequired
};

ProjectItem.defaultProps = {
  catalogs: [],
  project: null,
  pageContent: {},
  subTab: undefined
};

const mapStateToProps = (state, props) => {
  const { projectId } = props.params;
  const account = getAccount(state);
  const project = getProject(state);
  const statusOptions = account.getProjectStatusOptions();
  const filter = get(state, ["filter", "project", projectId], {});
  return {
    id: projectId,
    i18n: state.i18n,
    account,
    project,
    catalogs: getCatalogsForProject(state, { projectId: project?.id }),
    statusOptions,
    pageContent: state.pageContent,
    currentUser: account.getCurrentUser(),
    filter,
    activeTab: props.params.tab || "overview",
    subTab: props.location.query?.p,
    dialog: get(state, ["dialog", "project", projectId], {
      projectItem: false,
      activityItem: false
    }),
    userConfig: new UserConfig(),
    upgradeBundles: getUpgradeBundles(state),
    upgradeBundleGroups: getUpgradeBundleGroups(state),
    perspectiveOptions: getPerspectiveOptions(account, project?.id, state.i18n)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { projectId } = props.params;
  return {
    projectResource: new ProjectsResource(dispatch),
    toggleDialog: toggleDialogCreator(dispatch, projectId, "project"),
    actions: {
      activity: new ActivityResource.ActivitiesResource(dispatch),
      project: new ProjectsResource(dispatch),
      projectDeadlines: new ProjectDeadlinesResource(dispatch, projectId),
      sectionDeadlinesFn: sectionId => {
        return new SectionDeadlinesResource(dispatch, sectionId);
      },
      unitDeadlinesFn: unitId => {
        return new UnitDeadlinesResource(dispatch, unitId);
      }
    },
    accountResource: new AccountResource(dispatch),
    buyersResource: new ProjectBuyersResource(dispatch, projectId),
    unitsResource: new UnitsResource(dispatch, projectId),
    projectFeaturesResource: new ProjectFeaturesResource(dispatch),
    unitTypesResource: new UnitTypesResource(dispatch),
    projectRoomBooksResource: new ProjectRoomBooksResource(dispatch, projectId),
    upgradeBundlesResource: new UpgradeBundlesResource(dispatch, projectId),
    upgradeBundleGroupsResource: new UpgradeBundleGroupsResource(
      dispatch,
      projectId
    ),
    unitVariableTypesResource: new UnitVariableTypesResource(
      dispatch,
      projectId
    ),
    catalogsResource: new CatalogResource(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectItem);
