import React from "react";
import { connect } from "react-redux";
import {
  shape,
  string,
  number,
  bool,
  func,
  array,
  oneOfType,
  objectOf,
  instanceOf
} from "prop-types";
import { Dropdown, Form, Message, Modal } from "semantic-ui-react";
import { delay } from "lodash";

import Growl from "../../actions/growlActions";

import "./attachmentSelector.scss";
import FileUploader from "../dropzone/FileUploader";
import localStorage from "../../../shared/helpers/localStorage";

class AttachmentSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = AttachmentSelector.defaultState;
  }

  setDocumentType(type) {
    this.setState({
      documentType: type,
      dropdownOpen: false,
      open: true
    });
  }

  getRoleOptions() {
    const { account } = this.props;

    return account.getDocumentTypes()?.map(option => {
      return {
        value: option.id,
        icon: "file outline",
        text: option.label
      };
    });
  }

  onChange = (event, data) => {
    this.setDocumentType(data.value);
  };

  handleDropdownClose = () => {
    this.setState({ dropdownOpen: false });
  };

  handleDropdownOpen = id => {
    this.setState({ dropdownOpen: id });
  };

  toggleLoading = isLoading => {
    if (isLoading) {
      this.setState({ loading: isLoading });
    }
  };

  handleSuccess = attachment => {
    const { onSuccess } = this.props;
    onSuccess(attachment).then(() => {
      this.handleClose();
    });
  };

  handleClose = () => {
    delay(() => {
      this.setState(AttachmentSelector.defaultState);
    });
  };

  handleUpload = file => {
    const { resourceId, resourceName, growl, onSuccess } = this.props;
    const { documentType } = this.state;
    const formData = new FormData();

    this.setState({ loading: true });

    formData.append(`attachment[role]`, documentType);
    formData.append(`attachment[file]`, file);

    fetch(`/api/v1${resourceName}/${String(resourceId)}/attachments`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.get("token")}`
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.attachment) {
          growl.success(
            "message.file_upload.success.title",
            "message.file_upload.success.body"
          );
          return onSuccess(response.attachment);
        }
        return response.errors?.forEach(error => {
          growl.error(
            "message.errorBackend.title",
            "message.errorBackend.body",
            {
              timeout: 8000,
              bodyValues: { translatedBody: error }
            }
          );
        });
      });
  };

  handleOpen() {
    this.setState({ open: true });
  }

  renderContent() {
    const { i18n } = this.props;
    const { documentType, loading, error, dropdownOpen } = this.state;

    return (
      <Modal.Content>
        <Message error content={error} hidden={!error} />
        <Form loading={loading}>
          <Form.Field>
            <label>{i18n["attachment_type.title.one"]}</label>
            <Dropdown
              data-id="on_modal_selector"
              placeholder={i18n["attachment.actions.selectType"]}
              className="icon"
              onChange={this.onChange}
              search
              selectOnBlur={false}
              open={dropdownOpen === "on_modal_selector"}
              onBlur={this.handleDropdownClose}
              onOpen={() => this.handleDropdownOpen("on_modal_selector")}
              value={documentType}
              options={this.getRoleOptions()}
            />
          </Form.Field>
          <FileUploader
            handleFile={this.handleUpload}
            multiple
            onSuccess={this.handleClose}
          />
        </Form>
      </Modal.Content>
    );
  }

  renderTrigger() {
    const { i18n, compact } = this.props;
    const { documentType, dropdownOpen } = this.state;

    return (
      <Dropdown
        data-id="attachment_uploader_trigger"
        icon="plus"
        fluid
        compact={compact}
        labeled
        button
        placeholder={i18n["attachment.actions.upload"]}
        className="icon"
        onChange={this.onChange}
        search
        selectOnBlur={false}
        open={dropdownOpen === "trigger_dropdown"}
        onBlur={this.handleDropdownClose}
        onOpen={() => this.handleDropdownOpen("trigger_dropdown")}
        value={documentType}
        options={this.getRoleOptions()}
      />
    );
  }

  render() {
    const { label, i18n } = this.props;
    const { open } = this.state;

    const caption = label || i18n["attachment.actions.add"];

    return (
      <Modal
        data-component="attachmentSelector"
        trigger={this.renderTrigger()}
        closeIcon
        content={this.renderContent()}
        header={caption}
        open={open}
        onClose={this.handleClose}
      />
    );
  }
}

AttachmentSelector.defaultState = {
  documentType: null,
  open: false,
  loading: false,
  error: null,
  dropdownOpen: false
};

AttachmentSelector.propTypes = {
  account: shape({
    getDocumentTypes: func.isRequired
  }).isRequired,
  i18n: objectOf(oneOfType([string, bool, array, number])).isRequired,
  resourceId: number.isRequired,
  resourceName: string.isRequired,
  label: string.isRequired,
  onSuccess: func.isRequired,
  growl: instanceOf(Growl).isRequired,
  compact: bool
};

AttachmentSelector.defaultProps = {
  compact: false
};

const mapDispatchToProps = dispatch => ({
  growl: new Growl(dispatch)
});

export default connect(null, mapDispatchToProps)(AttachmentSelector);
