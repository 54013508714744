import React from "react";
import { connect } from "react-redux";

import { Button, Grid, Header, Loader, Segment } from "semantic-ui-react";
import { Link } from "react-router";
import { FormattedMessage } from "react-intl";
import { keyBy } from "lodash";
import PropTypes from "prop-types";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import { getAccount, getI18N, getProject } from "shared/selectors";
import PivotTable from "../analytics/PivotTable";
import RoomBookProgress from "./RoomBookProgress";

import { ProjectsResource } from "../../actions/projectActions";

import { getAnalytics, getReportDownloads } from "../../selectors/projects";

const PIVOT_CONFIGS = {
  legacy: {
    totalRevenue: {
      i18n: "gross",
      field: "gross_total_revenue"
    },
    totalCosts: {
      i18n: "net",
      field: "net_total_costs"
    }
  },
  gross: {
    totalRevenue: {
      i18n: "gross",
      field: "gross_total_revenue"
    },
    totalCosts: {
      i18n: "gross",
      field: "gross_total_costs"
    }
  },
  net: {
    totalRevenue: {
      i18n: "net",
      field: "net_total_revenue"
    },
    totalCosts: {
      i18n: "net",
      field: "net_total_costs"
    }
  }
};

class StatisticsTab extends React.Component {
  componentDidMount() {
    this.fetchAnalytics();
  }

  renderStatistics() {
    const { account, analytics } = this.props;

    const tradesDictionary = keyBy(account.getTrades(true), "id");
    const masterdata = analytics.master_data;

    return (
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header size="large">
              <FormattedMessage
                id="project.statistics.metrics.by_unit.title.label"
                defaultMessage="project.statistics.metrics.by_unit.title.label"
              />
            </Header>
            <div>
              {this.renderPivotTable(
                this.pivotRowsBySection(tradesDictionary, masterdata)
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>
              <FormattedMessage
                id="project.statistics.metrics.by_trade.title.label"
                defaultMessage="project.statistics.metrics.by_trade.title.label"
              />
            </Header>
            <div>
              {this.renderPivotTable(
                this.pivotRowsByTrade(tradesDictionary, masterdata)
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h3">Downloads</Header>
            <Segment.Group>{this.renderReportSegments()}</Segment.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderReportSegments = () => {
    const { reportDownloads } = this.props;
    return reportDownloads.map(report => (
      <Segment key={report.id}>
        <Grid>
          <Grid.Column width={10}>
            <Header as="h4">
              <Header.Content>
                <FormattedMessage
                  id={report.title}
                  defaultMessage={report.title}
                />
                <Header.Subheader>
                  <FormattedMessage
                    id={report.description}
                    defaultMessage={report.description}
                  />
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={6}>
            <Button
              as="a"
              className="right floated element"
              content="Download"
              target="_report"
              href={report.url}
            />
          </Grid.Column>
        </Grid>
      </Segment>
    ));
  };

  fetchAnalytics() {
    const { projectResource, projectId } = this.props;

    projectResource.fetchAnalytics(projectId, {});
  }

  pivotRows(tradesDictionary, masterdata) {
    const { project } = this.props;
    return [
      {
        field: "section",
        labelFn: row => {
          return masterdata.sections[row.section].label;
        },
        sortBy: row => {
          return masterdata.sections[row.section].label;
        }
      },
      {
        field: "unit",
        labelFn: row => {
          const unit = masterdata.units[row.unit];
          const label = `${unit.unit_number} - ${
            unit.buyers.length ? unit.buyers.join(", ") : "n.v."
          }`;

          return (
            <Link to={`/projects/${project.slug}/units/${unit.slug}/room-book`}>
              {label}
            </Link>
          );
        },
        sortBy: row => {
          const unit = masterdata.units[row.unit];
          return unit.unit_number;
        },
        hintFn: row => {
          const unit = masterdata.units[row.unit];
          return `${unit.label} - ${
            unit.buyers_full.length ? unit.buyers_full.join(", ") : "n.v."
          }`;
        }
      },
      {
        field: "trade",
        labelFn: row => {
          return tradesDictionary[row.trade.toUpperCase()].label;
        },
        sortBy: row => {
          return tradesDictionary[row.trade.toUpperCase()].label;
        }
      }
    ];
  }

  pivotRowsBySection(tradesDictionary, masterdata) {
    return this.pivotRows(tradesDictionary, masterdata);
  }

  pivotRowsByTrade(tradesDictionary, masterdata) {
    const rows = this.pivotRows(tradesDictionary, masterdata);
    return [rows[2], rows[0], rows[1]];
  }

  getActiveConfig() {
    const { account } = this.props;
    const toggles = account?.data?.account?.feature_toggles || {};

    if (toggles.show_reports_with_gross_amounts) {
      return PIVOT_CONFIGS.gross;
    }
    if (toggles.show_reports_with_net_amounts) {
      return PIVOT_CONFIGS.net;
    }
    return PIVOT_CONFIGS.legacy;
  }

  renderPivotTable(rows) {
    const { account, i18n, analytics } = this.props;

    const reportGroups = account.getLineItemLifeCycle().getReportGroups();
    const data = analytics.pivot_data;

    const sumFn = (reportGroup, metric) => {
      return (sum, row) => {
        if (reportGroup.indexOf(row.status) >= 0) {
          return sum + row[metric];
        }
        return sum;
      };
    };

    const renderAmount = value => {
      return <FormatCurrency amount={value} />;
    };

    const activeConfig = this.getActiveConfig();

    const cols = [
      {
        id: "progress",
        title: "",
        className: "progress-group",
        values: [
          {
            field: "progress",
            title: i18n["project.statistics.metrics.progress.label"],
            hint: i18n["project.statistics.metrics.progress.hint"],
            accuFn: () => {
              return [];
            },
            func: (accu, row) => {
              accu.push(row);
              return accu;
            },
            render: value => {
              return (
                <RoomBookProgress
                  unitItem={{ id: "x", room_book_id: "x" }}
                  i18n={i18n}
                  unitStatistics={{ x: value }}
                  processDefinition={account.getLineItemLifeCycle()}
                  withBuyerPortal={account.isEnabled("buyer_portal")}
                />
              );
            }
          }
        ]
      },
      {
        id: "revenue",
        title:
          i18n[
            `project.statistics.metrics.revenue.${activeConfig.totalRevenue.i18n}.label`
          ],
        hint:
          i18n[
            `project.statistics.metrics.revenue.${activeConfig.totalRevenue.i18n}.hint`
          ],
        values: [
          {
            field: "amount_offered",
            title: i18n["project.statistics.metrics.amount_offered.label"],
            hint: i18n["project.statistics.metrics.amount_offered.hint"],
            titleClass: "right",
            cellClass: "right",
            func: sumFn(
              reportGroups.rg_offered,
              activeConfig.totalRevenue.field
            ),
            render: renderAmount
          },
          {
            field: "amount_pending",
            title: i18n["project.statistics.metrics.amount_pending.label"],
            hint: i18n["project.statistics.metrics.amount_pending.hint"],
            titleClass: "right",
            cellClass: "right",
            func: sumFn(
              reportGroups.rg_pending_offer,
              activeConfig.totalRevenue.field
            ),
            render: renderAmount
          },
          {
            field: "amount_ordered",
            title: i18n["project.statistics.metrics.amount_ordered.label"],
            hint: i18n["project.statistics.metrics.amount_ordered.hint"],
            titleClass: "right",
            cellClass: "right",
            func: sumFn(
              reportGroups.rg_ordered,
              activeConfig.totalRevenue.field
            ),
            render: renderAmount
          },
          {
            field: "amount_cancelled",
            title: i18n["project.statistics.metrics.amount_cancelled.label"],
            hint: i18n["project.statistics.metrics.amount_cancelled.hint"],
            titleClass: "right",
            cellClass: "right",
            func: sumFn(
              reportGroups.rg_cancelled,
              activeConfig.totalRevenue.field
            ),
            render: renderAmount
          }
        ]
      },
      {
        id: "costs",
        title:
          i18n[
            `project.statistics.metrics.costs.${activeConfig.totalCosts.i18n}.label`
          ],
        hint:
          i18n[
            `project.statistics.metrics.costs.${activeConfig.totalCosts.i18n}.hint`
          ],
        values: [
          {
            field: "costs_ordered",
            title: i18n["project.statistics.metrics.costs_ordered.label"],
            hint: i18n["project.statistics.metrics.costs_ordered.hint"],
            titleClass: "right",
            cellClass: "right",
            func: sumFn(reportGroups.rg_ordered, activeConfig.totalCosts.field),
            render: renderAmount
          },
          {
            field: "costs_contracted",
            title: i18n["project.statistics.metrics.costs_contracted.label"],
            hint: i18n["project.statistics.metrics.costs_contracted.hint"],
            titleClass: "right",
            cellClass: "right",
            func: sumFn(
              reportGroups.rg_contracted,
              activeConfig.totalCosts.field
            ),
            render: renderAmount
          }
        ]
      }
    ];

    return <PivotTable data={data} rows={rows} cols={cols} />;
  }

  render() {
    const { analytics, project } = this.props;
    if (analytics && project) {
      return this.renderStatistics();
    }
    return (
      <div className="margin top spacious">
        <Loader active />
      </div>
    );
  }
}

StatisticsTab.propTypes = {
  i18n: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  project: PropTypes.object,
  analytics: PropTypes.object,
  projectResource: PropTypes.instanceOf(ProjectsResource).isRequired
};

const mapStateToProps = state => {
  return {
    i18n: getI18N(state),
    account: getAccount(state),
    project: getProject(state),
    analytics: getAnalytics(state),
    reportDownloads: getReportDownloads(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    projectResource: new ProjectsResource(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsTab);
