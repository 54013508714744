import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { string, arrayOf } from "prop-types";
import { getAccount } from "shared/selectors";

import SubLineItemModel from "builder_portal/models/roomBook/SubLineItemModel";
import { getDerivedIds } from "builder_portal/selectors/roomBooks/descendantsInfo";
import { getUnitVariableTypesOptions } from "builder_portal/selectors/unitVariables";
import OptionsShape from "shared/shapes/options.shape";
import toggleDialogCreator from "../../helpers/toggleDialogCreator";
import SubLineItemDialog from "./SubLineItemDialog";

class SubLineItemDialogContainer extends Component {
  render() {
    const {
      derivedIds,
      dialogModel,
      i18n,
      toggleDialog,
      ctrl,
      attachments,
      context,
      module,
      unitVariableTypesOptions,
      projectId
    } = this.props;
    if (dialogModel) {
      const subLineItemModel = new SubLineItemModel(
        dialogModel.model,
        dialogModel.products,
        dialogModel.isTemplate
      );
      return (
        <SubLineItemDialog
          module={module}
          context={context}
          i18n={i18n}
          trades={dialogModel.trades}
          attachments={attachments}
          isTemplate={dialogModel.isTemplate}
          isVisible
          roomBookId={dialogModel.roomBookId}
          toggleDialog={toggleDialog}
          model={dialogModel.model}
          products={dialogModel.products}
          ctrl={ctrl}
          autoFocus={!dialogModel.model.product_group_id}
          handleUpdate={dialogModel.handleUpdate}
          subLineItemModel={subLineItemModel}
          derivedIds={derivedIds}
          initialPage={dialogModel.initialPage}
          unitVariableTypesOptions={unitVariableTypesOptions}
          projectId={projectId}
        />
      );
    }
    return <span />;
  }
}

SubLineItemDialogContainer.propTypes = {
  module: string,
  unitVariableTypesOptions: arrayOf(OptionsShape)
};

SubLineItemDialogContainer.defaultProps = {
  module: null,
  unitVariableTypesOptions: []
};

const mapStateToProps = (state, props) => {
  const { module, id } = props;
  const attachments = get(state, ["pageContent", "attachments"], []);
  return {
    i18n: state.i18n,
    featureToggles: {},
    attachments,
    dialogModel: get(state, ["dialog", module, id, "subLineItemDialog"]),
    context: {
      account: getAccount(state)
    },
    derivedIds: getDerivedIds(state),
    unitVariableTypesOptions: getUnitVariableTypesOptions(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { module, id } = props;
  return {
    toggleDialog: toggleDialogCreator(dispatch, id, module)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubLineItemDialogContainer);
