import React, { Component } from "react";
import { get } from "lodash";
import { Helmet } from "react-helmet";
import { Button, Grid, Header, Icon, Loader, Segment } from "semantic-ui-react";
import { Link } from "react-router";
import { FormattedMessage } from "react-intl";
import { createSelector } from "reselect";
import {
  arrayOf,
  func,
  instanceOf,
  oneOf,
  shape,
  string,
  number
} from "prop-types";
import { connect } from "react-redux";
import UnitRoomBookResource from "builder_portal/actions/unitRoomBookActions";
import { getSortedSections } from "shared/selectors/sections";
import { ProjectStoreysResource } from "builder_portal/actions/projectStoreysActions";
import {
  UnitsResource,
  UnitResource
} from "builder_portal/actions/unitActions";
import ProjectBuyersResource from "builder_portal/actions/buyerActions";
import ConfiguratorDesignsResource from "builder_portal/actions/configuratorDesignActions";
import UnitTypesResource from "builder_portal/actions/unitTypesActions";
import { UnitFeatureResource } from "builder_portal/actions/unitFeatureGroupsActions";
import {
  AccountShape,
  BuyerShape,
  I18nShape,
  RoomBookShape,
  UnitShape,
  ProjectShape
} from "../../../shared/shapes";
import { DEPRECATED_PageContentShape } from "../../../shared/shapes/deprecated_pageContent.shape";
import { getUnitBuyersWithStatus, getRoomBook, getUnit } from "../../selectors";
import { ProjectRoomBooksResource } from "../../actions/roomBookActions";
import { AttachmentResource } from "../../actions/attachmentsActions";
import { If, ShowIf } from "../../../shared/components/elements/Conditions";
import { UnitDeadlinesResource } from "../../actions/projectDeadlinesActions";
import { ProjectsResource } from "../../actions/projectActions";
import { ProjectBuyerInvitationResource } from "../../actions/buyerActions";
import UnitCatalogResource from "../../actions/unitCatalogActions";
import { getAccount, getProject } from "../../../shared/selectors";
import UnitDeadlines from "./UnitDeadlines";
import UnitStatistics from "./UnitStatistics";
import UnitItemAttachmentWrapper from "./UnitItemAttachmentWrapper";
import BuyerInvitationStatus from "../buyer/BuyerInvitationStatus";
import RoomBookCreator from "../roomBook/RoomBookCreator";
import RoomBook from "../roomBook/RoomBook";
import Activities from "./Activities";
import TabMenu from "./TabMenu";
import Growl from "../../actions/growlActions";
import MessageThreadsContainer from "../messages/MessageThreadsContainer";
import MessagesResource from "../../actions/messagesActions";
import "./unit.scss";
import DeadlineShape from "../../../shared/shapes/deadline.shape";
import MarketingPortalLink from "../roomBook/marketingPortalLink";
import UpgradeBundlesResource from "../../actions/upgradeBundlesActions";
import ProjectRoomsLoader from "../projectRooms/ProjectRoomsLoader";
import UnitProspect from "../project/unitProspect/UnitProspect";
import UnitSetup from "./UnitSetup";
import UnitDialog from "./UnitDialog";

class UnitContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, bundleLoading: false };
  }

  componentDidMount() {
    const { actions, growl, projectId, unitId } = this.props;
    const {
      unitRoomBook,
      project,
      buyers,
      catalog,
      upgradeBundlesResource,
      unit,
      configuratorDesignsResource,
      unitTypesResource,
      unitFeatureResource,
      projectStoreysResource
    } = actions;
    this.setState({ bundleLoading: true });

    Promise.all([
      unitRoomBook.get(),
      unit.getApplicableActivities(unitId),
      project.get(projectId),
      project.fetchStructure(projectId),
      project.fetchMembers(projectId),
      catalog.get(),
      upgradeBundlesResource.fetchAll(),
      configuratorDesignsResource.fetchAll(),
      unitTypesResource.fetchAll(),
      unitFeatureResource.fetchAll(),
      projectStoreysResource.fetchAll(),
      this.reload(true)
    ])
      .then(() => {
        // buyers from unit deep fetch are actual unit buyers (redux: unit_buyers), but we must fetch all the buyers(redux: buyers)
        buyers.fetchAll();
        this.setState({ bundleLoading: false });

        return Promise.all([unitRoomBook.getExports()]);
      })
      .catch(error => {
        growl.error("message.errorLoad.title", "message.errorLoad.body", {
          timeout: 5000
        });
        this.setState({ bundleLoading: false });
        throw error;
      });
  }

  reload(deep = false) {
    const { unitResource, unitId } = this.props;
    if (deep) {
      this.setState({ loading: true });
    }
    return unitResource.get(unitId, deep).then(() => {
      this.setState({ loading: false });
    });
  }

  renderSpinner = unitLoaded => {
    return (
      <Segment>
        <If condition={!unitLoaded}>
          <Header as="h3" textAlign="center">
            <FormattedMessage
              id="unit.actions.unitLoading"
              defaultMessage="unit.actions.unitLoading"
            />
          </Header>
        </If>
        <If condition={!!unitLoaded}>
          <Header as="h3" textAlign="center">
            <FormattedMessage
              id="unit.actions.roomBookLoading"
              defaultMessage="unit.actions.roomBookLoading"
            />
          </Header>
        </If>
        <Loader active inline="centered" />
      </Segment>
    );
  };

  sectionOptions() {
    const { sections } = this.props;
    return sections.map(sectionItem => {
      return {
        key: sectionItem.id,
        value: sectionItem.id,
        text: `${sectionItem.code}: ${sectionItem.name}`
      };
    });
  }

  renderUnit() {
    const {
      unitId,
      account,
      i18n,
      pageContent,
      unitBuyers,
      actions,
      activeTab,
      context,
      roomBook,
      unit,
      project,
      projectId,
      unitsResource,
      unitResource
    } = this.props;
    const hasBuyerPortalFeature = account.isEnabled("buyer_portal");
    const pageName =
      unit && unit.display_name
        ? [
            i18n["unit.singular"],
            unit.display_name,
            i18n["meta.app.name"]
          ].join(" - ")
        : [i18n["unit.singular"], i18n["meta.app.name"]].join(" - ");

    const buyerNames = unitBuyers.map(buyer => buyer.label).join(", ");

    return (
      <div data-component="unit">
        <ProjectRoomsLoader projectId={project?.id} />
        <Helmet title={pageName} />

        {project && unit && unit.display_name && (
          <div>
            <Grid>
              <Grid.Column width="16">
                <Header sub>
                  <Link
                    to={`/projects/${project.slug}/sectionAndUnit/`}
                    onlyActiveOnIndex
                  >
                    {unit.project.label} / {unit.section.label}
                  </Link>
                </Header>
              </Grid.Column>
              <Grid.Column width="8">
                <Header>
                  <Icon name="cube" />
                  {unit.display_name} {buyerNames}
                </Header>
              </Grid.Column>
              <Grid.Column width="8" textAlign="right">
                <Button.Group className="buyerPortalActions">
                  {unitBuyers.length > 0 && (
                    <BuyerInvitationStatus
                      enabled={hasBuyerPortalFeature}
                      buyers={unitBuyers}
                      i18n={i18n}
                      actions={actions}
                      onUpdate={() => this.reload(false)}
                    />
                  )}
                  {hasBuyerPortalFeature && this.renderBuyerPortalLink()}

                  <UnitDialog
                    button={
                      <Button icon basic color="grey">
                        <Icon color="grey" name="setting" />
                      </Button>
                    }
                    i18n={i18n}
                    resource={unitsResource}
                    deepResource={unitResource}
                    unit={unit}
                    sectionOptions={this.sectionOptions()}
                    withBuyerPortal={account.isEnabled("buyer_portal")}
                    projectId={projectId}
                  />
                </Button.Group>
              </Grid.Column>
            </Grid>
          </div>
        )}

        <If condition={!!project}>
          <TabMenu
            i18n={i18n}
            activeTab={activeTab}
            rootUrl={`/projects/${project?.slug}/units/${unitId}`}
            statistics={get(pageContent, "unit.statistics")}
            unit={unit}
            project={project}
            account={account}
          />
        </If>

        <Segment attached="bottom" data-component="tabContainer">
          <ShowIf condition={activeTab === "room-book"}>
            {this.renderRoomBook(roomBook, context)}
          </ShowIf>

          <If condition={activeTab === "activities"}>
            {this.renderActivities()}
          </If>

          <If condition={activeTab === "deadlines"}>
            {this.renderDeadlines(roomBook, context)}
          </If>

          <If condition={activeTab === "documents"}>
            <UnitItemAttachmentWrapper
              i18n={i18n}
              context={context}
              actions={actions}
              unitId={unit && unit.id}
              onChange={() => this.reload(true)}
            />
          </If>

          {activeTab === "messages" && (
            <MessageThreadsContainer
              customParams={{
                project: `${project?.id}`,
                unit: `${unit.id}`
              }}
            />
          )}

          {activeTab === "statistics" && this.renderOverview()}

          {activeTab === "unit_variables" && <UnitSetup />}
          {activeTab === "prospect_list" && (
            <UnitProspect
              unitId={unitId}
              projectId={projectId}
              isProjectLeve={false}
            />
          )}
        </Segment>
      </div>
    );
  }

  renderActivities() {
    const { unitId, projectId } = this.props;

    return (
      <div data-component="activityListContainer">
        <Activities id={unitId} projectId={projectId} />
      </div>
    );
  }

  renderOverview() {
    const { unitId } = this.props;
    return <UnitStatistics unitId={unitId} />;
  }

  renderDeadlines() {
    const { i18n, account, deadlines, actions } = this.props;

    return (
      <UnitDeadlines
        i18n={i18n}
        account={account}
        deadlines={deadlines}
        actions={actions}
        onUpdate={() => this.reload(false)}
      />
    );
  }

  renderRoomBook(roomBook, context) {
    const { projectId, unit } = this.props;
    const { bundleLoading } = this.state;

    if (roomBook) {
      return (
        <RoomBook
          id={roomBook.id}
          projectId={projectId}
          roomBook={roomBook}
          context={context}
        />
      );
    }
    if (unit && bundleLoading) {
      return <div>{this.renderSpinner(true)}</div>;
    }

    if (unit && !bundleLoading) {
      return (
        <div>
          <Header
            as="h3"
            textAlign="center"
            className="margin top bottom spacious"
          >
            <FormattedMessage
              id="roomBook.title.missing"
              default="Es wurde noch kein Raumbuch angelegt"
            />
          </Header>
          <Grid>
            <Grid.Column width="6" />
            <Grid.Column width="4">
              <RoomBookCreator onCreate={() => this.reload(true)} />
            </Grid.Column>
            <Grid.Column width="6" />
          </Grid>
        </div>
      );
    }

    return (
      <Header as="h3" textAlign="center" className="margin top bottom spacious">
        <FormattedMessage
          id="unit.title.missing"
          default="Es wurden noch keine Wohneinheiten angelegt"
        />
      </Header>
    );
  }

  renderBuyerPortalLink() {
    const {
      pageContent: {
        unit: {
          marketing_portal_url: marketingPortalUrl,
          buyer_portal_url: buyerPortalUrl,
          sample: isMarketingSample
        }
      }
    } = this.props;

    if (isMarketingSample) {
      return (
        <MarketingPortalLink
          label={
            <FormattedMessage
              id="unit.preview.marketing"
              defaultMessage="unit.preview.marketing"
            />
          }
          linkProps={{ to: marketingPortalUrl }}
        />
      );
    }
    return (
      <Button
        as="a"
        id="preview-portal"
        target="_buyer_portal"
        href={buyerPortalUrl}
        basic
        color="green"
      >
        <Icon name="desktop" />
        <FormattedMessage
          id="unit.preview.buyer"
          defaultMessage="Erwerberansicht"
        />
      </Button>
    );
  }

  render() {
    const { loading } = this.state;
    return loading ? this.renderSpinner() : this.renderUnit();
  }
}

const makeGetRoomBookContext = () => {
  return createSelector(
    [getAccount, getUnitBuyersWithStatus, (_, unitId) => unitId],
    (account, unitBuyers, unitId) => {
      return {
        account,
        unitId,
        unitBuyers
      };
    }
  );
};

const makeMapStateToProps = () => {
  const getRoomBookContext = makeGetRoomBookContext();
  return (state, props) => {
    const { pageContent, i18n } = state;
    const projectId = props.params.projectId || undefined;
    const unitBuyers = getUnitBuyersWithStatus(state);
    const account = getAccount(state);
    const project = getProject(state);
    const activeTab = props.params.tab || "overview";
    const roomBook = getRoomBook(state);
    const unitId = props.params.id || null;
    const context = getRoomBookContext(state, unitId);
    const unit = getUnit(state);
    const deadlines = pageContent?.deadlines;
    const sections = getSortedSections(state);

    return {
      unitId,
      activeTab,
      projectId,
      i18n,
      pageContent,
      unitBuyers,
      account,
      project,
      context,
      roomBook,
      unit,
      deadlines,
      sections
    };
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const unitId = props.params.id || null;
  const projectId = props.params.projectId || null;

  return {
    loadMessages: params => {
      const resource = new MessagesResource(dispatch);
      return resource.fetchAll({ ...params, limit: 20 });
    },
    unitResource: new UnitResource(dispatch),
    unitsResource: new UnitsResource(dispatch, projectId),
    unitId,
    projectId,
    actions: {
      project: new ProjectsResource(dispatch),
      buyers: new ProjectBuyersResource(dispatch, projectId),
      unit: new UnitResource(dispatch),
      roomBook: new ProjectRoomBooksResource(dispatch, projectId),
      attachment: new AttachmentResource(dispatch),
      buyerInvitationResourceCreator: buyerId => {
        return new ProjectBuyerInvitationResource(dispatch, projectId, buyerId);
      },
      deadlines: new UnitDeadlinesResource(dispatch, unitId),
      catalog: new UnitCatalogResource(dispatch, unitId),
      unitRoomBook: new UnitRoomBookResource(dispatch, unitId),
      upgradeBundlesResource: new UpgradeBundlesResource(dispatch, projectId),
      configuratorDesignsResource: new ConfiguratorDesignsResource(
        dispatch,
        projectId
      ),
      unitTypesResource: new UnitTypesResource(dispatch),
      unitFeatureResource: new UnitFeatureResource(dispatch, projectId),
      projectStoreysResource: new ProjectStoreysResource(dispatch, projectId)
    },
    growl: new Growl(dispatch)
  };
};

UnitContainer.defaultProps = {
  pageContent: {},
  activeTab: "overview",
  unitId: null,
  unit: null,
  roomBook: null,
  context: null,
  project: null,
  deadlines: []
};

UnitContainer.propTypes = {
  unitId: string,
  projectId: string.isRequired,
  activeTab: oneOf([
    "overview",
    "statistics",
    "room-book",
    "activities",
    "deadlines",
    "documents",
    "messages",
    "unit_variables",
    "prospect_list"
  ]),
  i18n: I18nShape.isRequired,
  account: AccountShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageContent: DEPRECATED_PageContentShape,
  sections: arrayOf(shape({ id: number, code: string, name: string }))
    .isRequired,
  actions: shape({
    project: instanceOf(ProjectsResource),
    buyers: instanceOf(ProjectBuyersResource).isRequired,
    unit: instanceOf(UnitResource),
    roomBook: instanceOf(ProjectRoomBooksResource),
    attachment: instanceOf(AttachmentResource),
    buyerInvitationResourceCreator: func,
    deadlines: instanceOf(UnitDeadlinesResource),
    catalog: instanceOf(UnitCatalogResource),
    unitRoomBook: instanceOf(UnitRoomBookResource),
    upgradeBundlesResource: instanceOf(UpgradeBundlesResource).isRequired,
    configuratorDesignsResource: instanceOf(ConfiguratorDesignsResource)
      .isRequired,
    unitTypesResource: instanceOf(UnitTypesResource).isRequired,
    unitFeatureResource: instanceOf(UnitFeatureResource).isRequired,
    projectStoreysResource: instanceOf(ProjectStoreysResource).isRequired
  }).isRequired,
  unitBuyers: arrayOf(BuyerShape).isRequired,
  unitResource: instanceOf(UnitResource).isRequired,
  unitsResource: instanceOf(UnitsResource).isRequired,
  growl: instanceOf(Growl).isRequired,
  roomBook: RoomBookShape,
  project: ProjectShape,
  unit: UnitShape,
  deadlines: arrayOf(DeadlineShape),
  context: shape({
    account: AccountShape,
    buyers: arrayOf(BuyerShape),
    unitId: string
  })
};

export default connect(makeMapStateToProps, mapDispatchToProps)(UnitContainer);
