import { get, isMatch } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Header,
  Icon,
  Loader
} from "semantic-ui-react";
import { routerShape } from "react-router";
import { ContractorShape, ContractorAssignmentShape } from "shared/shapes";
import {
  getContractors,
  getContractorAssignments,
  getI18N
} from "shared/selectors";
import ImportInquiryModal from "../../../buyer_portal/components/inquiry/importInquiryModal";
import ImportInquiryTable from "../../../buyer_portal/components/inquiry/importInquiryTable";
import ActivityController from "../../containers/activity/activityController";
import ActivityModel from "../../containers/activity/activityModel";
import colorByDueDays from "../../helpers/colorByDueDays";
import AttachmentSelector from "../attachment/AttachmentSelector";
import CartExportDialog from "../cart/CartExportDialog";
import DueLabel from "../dueLabel/DueLabel";
import { withLoadingIndicator } from "../helpers/withLoadingIndicator";
import SuccessorActivity from "./SuccessorActivity";
import ActivityActions from "./ActivityActions";
import ActivityAssignmentDialog from "./ActivityAssignmentDialog";
import ActivityTransitionControl from "./ActivityTransitionControl";
import ContractorAssignmentDialog from "./ContractorAssignmentDialog";
import Task from "./Task";
import { withRouter } from "../../../shared/helpers/withRouter";

import "./today.scss";
import OnOffice from "./OnOffice";

class Today extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      retailSubLineItems: props.ctxModel.getRetailSubLineItems(),
      currentShortcut: undefined,
      transitioned: undefined
    };

    const { router, ctxModel } = props;
    const activityAction = get(router, "location.state.activityAction");

    if (activityAction) {
      const state = ctxModel.getCurrentState();
      const shortcuts = state.getShortcuts();
      const index = shortcuts.findIndex(shortcut =>
        isMatch(shortcut.action, activityAction)
      );
      if (index >= 0) {
        const shortcut = shortcuts[index];
        this.state.currentShortcut = `shortcut-${shortcut.action.id}-${index}`;
      }
    }

    const attachmentId = get(router, "location.state.attachmentId");
    if (attachmentId) {
      this.state.defaultAttachment = attachmentId;
    }

    this.withLoadingIndicator = withLoadingIndicator.bind(this);
    this.handleLoading = this.handleLoading.bind(this);
    this.handleTransition = this.handleTransition.bind(this);
  }

  handlePriceInquiryImportSubmit() {
    const { ctxCtrl } = this.props;
    const { retailSubLineItems } = this.state;
    const updatedLineItems = [];
    Object.values(retailSubLineItems).forEach(retailSubLineItem => {
      retailSubLineItem.retails.forEach(retailItem => {
        updatedLineItems.push({
          id: retailSubLineItem.id,
          trade: retailItem.trade.tradeId,
          costs: retailItem.purchasePrice
        });
      });
      updatedLineItems.push({
        id: retailSubLineItem.id,
        price: retailSubLineItem.price
      });
    });

    this.handleTransition({ state: { started: true } });

    return ctxCtrl
      .subLineItemResource()
      .update(updatedLineItems)
      .then(() => ctxCtrl.refresh())
      .then(() => this.handleTransition({ state: { completed: true } }));
  }

  handleLoading(isLoading) {
    this.setState({ isLoading });
  }

  handleShortcutFn(id) {
    return () => {
      this.setState({ currentShortcut: id });
    };
  }

  handleTransition(state) {
    if (state.completed) {
      this.setState({ isLoading: false, transitioned: true });
    } else if (state.started) {
      this.setState({ isLoading: true, transitioned: false });
    } else {
      this.setState({ isLoading: false, transitioned: false });
    }
  }

  renderAttachmentSelector(shortcut) {
    const { ctxModel, ctxCtrl } = this.props;

    return (
      <AttachmentSelector
        account={ctxModel.account}
        resourceId={ctxModel.id}
        resourceName="/activities"
        i18n={ctxModel.i18n}
        compact
        label={shortcut.action.label}
        toggleLoading={this.handleLoading}
        onSuccess={this.withLoadingIndicator(ctxCtrl.refresh)}
      />
    );
  }

  renderCartExport(item) {
    const {
      ctxModel,
      ctxCtrl,
      contractors,
      contractorAssignments
    } = this.props;

    if (ctxModel.isReady()) {
      const exports = ctxModel.getExportsByRole(item.action.cart_export_role);

      if (exports.length) {
        const format = item.action.cart_export_format;

        const attachment = {
          activity_id: ctxModel.getActivity().id,
          display_name: get(exports[0], "file_name"),
          role: get(exports[0], "role"),
          format,
          export_id: get(exports[0], "id")
        };

        const trigger = (
          <Button
            content={
              <div className="leftAlignedWithIcon">{item.action.label}</div>
            }
            fluid
            icon="book"
            labelPosition="left"
          />
        );

        return (
          <CartExportDialog
            title={item.action.label}
            exports={exports}
            attachment={attachment}
            button={trigger}
            onSave={this.withLoadingIndicator(ctxCtrl.exportAsAttachment)}
            mode="ATTACHMENT"
            trades={ctxModel.getAssignedTrades()}
            contractors={contractors}
            contractorAssignments={contractorAssignments}
          />
        );
      }
      return (
        <i>Unknown cart export &quot;{item.action.cart_export_role}&quot;</i>
      );
    }
    return null;
  }

  renderContractorSelector(item) {
    const { ctxCtrl } = this.props;

    return (
      <ContractorAssignmentDialog
        compact
        label={item.action.label}
        onSaved={this.withLoadingIndicator(ctxCtrl.refresh)}
      />
    );
  }

  renderMacro(item, identifier) {
    const { ctxModel, ctxCtrl } = this.props;
    const { currentShortcut, defaultAttachment } = this.state;

    return (
      <ActivityActions
        account={ctxModel.account}
        i18n={ctxModel.i18n}
        activity={ctxModel.get("activity")}
        buyer={ctxModel.get("buyer")}
        buyers={ctxModel.get("buyers")}
        contractors={ctxModel.get("contractors")}
        project={ctxModel.get("project")}
        contacts={ctxModel.get("contacts")}
        attachments={ctxModel.get("attachments")}
        macros={ctxModel.get("macros")}
        handleUpdate={this.withLoadingIndicator(ctxCtrl.refresh)}
        handleOpen={this.handleShortcutFn(identifier)}
        handleClose={this.handleShortcutFn(null)}
        openActionId={
          currentShortcut === identifier ? item.action.macro_id : undefined
        }
        defaultAttachments={[defaultAttachment]}
        compact
        fluid
        messageTypes={[item.action.macro_id]}
        label={item.action.label}
        memosResource={ctxCtrl.getMemosResource()}
        ctxCtrl={ctxCtrl}
      />
    );
  }

  renderNewActivity(item) {
    const { ctxModel, ctxCtrl } = this.props;

    if (ctxModel.hasProjectProcessDefinition(item.action.process_type)) {
      return (
        <SuccessorActivity
          item={item}
          ctxModel={ctxModel}
          ctxCtrl={ctxCtrl}
          toggleLoading={this.handleLoading}
        />
      );
    }

    return "";
  }

  renderOnOfficeActions(item) {
    const { ctxModel } = this.props;

    if (ctxModel.hasProjectProcessDefinition(item.action.class)) {
      return <OnOffice hooksConfig={item} activity={ctxModel} />;
    }

    return "";
  }

  renderShortCuts(shortcuts) {
    const { ctxCtrl, ctxModel } = this.props;
    const { retailSubLineItems, currentShortcut } = this.state;

    return shortcuts.reduce((accu, item, idx) => {
      const id = `shortcut-${item.action.id}-${idx}`;
      let button;
      switch (item.action.id) {
        case "document_upload":
          button = this.renderAttachmentSelector(item);
          break;
        case "contractor_assignment":
          button = this.renderContractorSelector(item);
          break;
        case "import_inquiry":
          if (Object.keys(retailSubLineItems).length > 0) {
            button = (
              <ImportInquiryModal
                ctxCtrl={ctxCtrl}
                ctxModel={ctxModel}
                trigger={<Button>{item.action.label}</Button>}
                onSubmit={() => this.handlePriceInquiryImportSubmit()}
              >
                <ImportInquiryTable
                  retailSubLineItems={retailSubLineItems}
                  onChange={({ nextRetailSubLineItems }) => {
                    this.setState({
                      retailSubLineItems: {
                        ...retailSubLineItems,
                        ...nextRetailSubLineItems
                      }
                    });
                  }}
                />
              </ImportInquiryModal>
            );
          }
          break;
        case "cart_export":
          button = this.renderCartExport(item);
          break;
        case "macro":
          button = this.renderMacro(item, id);
          break;
        case "new_activity":
          button = this.renderNewActivity(item);
          break;
        case "on_office":
          button = this.renderOnOfficeActions(item);
          break;
        default:
          button = false;
      }

      if (button) {
        const shortcutId = currentShortcut;
        const display = !shortcutId || shortcutId === id ? "block" : "none";
        accu.push(
          <div
            className="shortCutItem"
            key={id}
            style={{ display }}
            data-shortcut={item.action.id}
          >
            <div className="left floated element">{item.label}</div>
            <div className="right floated element">{button}</div>
          </div>
        );
      }

      return accu;
    }, []);
  }

  renderTasks() {
    const { ctxModel, ctxCtrl } = this.props;
    return <Task ctxModel={ctxModel} ctxCtrl={ctxCtrl} />;
  }

  render() {
    const { i18n, ctxModel, ctxCtrl } = this.props;
    const { transitioned, isLoading, currentShortcut } = this.state;
    const activity = ctxModel.getActivity();
    const state = ctxModel.getCurrentState();
    const isTerminal = state.terminal;
    const shortcuts = this.renderShortCuts(state.getShortcuts());
    const hasMessageForm = !!currentShortcut;

    const color = colorByDueDays(activity.deadline_due_at);

    return (
      <div
        data-component="timelineItem"
        className={`today${isTerminal ? " terminal" : ""}`}
        style={{ borderColor: colorByDueDays(activity.deadline_due_at) }}
      >
        <div className="bullet-pointer">
          <Icon
            size="large"
            circular
            name="circle"
            style={{ backgroundColor: color, color }}
          />
        </div>
        <Container data-component="todayDetails">
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
          <Grid stackable verticalAlign="middle">
            <Grid.Column width="11">
              <Header size="small">
                <span data-attr="status">{state.label}</span>
              </Header>
            </Grid.Column>

            <Grid.Column width="5" textAlign="right">
              {activity.deadline_due_at && (
                <DueLabel date={activity.deadline_due_at} />
              )}
            </Grid.Column>

            <Grid.Column width="16">
              <p className="summary">{state.summary}</p>
            </Grid.Column>

            {!!shortcuts.length && (
              <Grid.Column width="16" className="shortCuts">
                <div className="shortCutList">{shortcuts}</div>
              </Grid.Column>
            )}

            {!hasMessageForm && this.renderTasks()}

            {!hasMessageForm && (
              <Grid.Column width="16">
                <ActivityTransitionControl
                  i18n={i18n}
                  ctxModel={ctxModel}
                  ctxCtrl={ctxCtrl}
                  onTransition={this.handleTransition}
                />
              </Grid.Column>
            )}
          </Grid>
        </Container>
        {transitioned && (
          <ActivityAssignmentDialog
            i18n={i18n}
            ctxModel={ctxModel}
            ctxCtrl={ctxCtrl}
          />
        )}
      </div>
    );
  }
}

Today.propTypes = {
  // TODO replace with i18n shape
  // eslint-disable-next-line react/forbid-prop-types
  i18n: PropTypes.object.isRequired,
  ctxCtrl: PropTypes.instanceOf(ActivityController).isRequired,
  ctxModel: PropTypes.instanceOf(ActivityModel).isRequired,
  router: routerShape.isRequired,
  contractors: PropTypes.arrayOf(ContractorShape),
  contractorAssignments: PropTypes.arrayOf(ContractorAssignmentShape)
};

Today.defaultProps = {
  contractors: [],
  contractorAssignments: []
};

const mapStateToProps = state => {
  return {
    i18n: getI18N(state),
    contractors: getContractors(state),
    contractorAssignments: getContractorAssignments(state)
  };
};

export default connect(mapStateToProps)(withRouter(Today));
