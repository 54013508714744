import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { arrayOf, bool, func, number, shape, string, object } from "prop-types";
import { omit, keyBy } from "lodash";
import moment from "moment";
import {
  Icon,
  Input,
  Message,
  Pagination,
  Popup,
  Segment,
  Table,
  Label
} from "semantic-ui-react";
import { getI18N } from "shared/selectors";
import { MessageThreadShape } from "shared/shapes/messageThread.shape";
import { If } from "shared/components/elements/Conditions";
import { getMessageLabels } from "builder_portal/selectors/messages";
import {
  getMessages,
  getMessagesFilter,
  getMessagesStatistics,
  getSearchParams,
  getMessageDraftsForThreads
} from "../../selectors";
import { browserHistory } from "../../../shared/routes/browserHistory";
import MessagesResource from "../../actions/messagesActions";
import silentHandleApiRequestErrors from "../../../shared/helpers/silentHandleApiRequestErrors";
import { setMessagesFilter } from "../../actions/filters/messages";
import { I18nShape } from "../../../shared/shapes/i18n.shape";
import MessageBodyV1 from "./MessageBodyV1";

const MAX_THREADS = 20;
const POPUP_MOUSE_ENTER_DELAY = 500; // ms
const POPUP_MOUSE_LEAVE_DELAY = POPUP_MOUSE_ENTER_DELAY / 3;
const REFRESH_INTERVAL = 60 * 1000; // 1 min

export const displayDate = dateString => {
  const now = moment();
  const updatedAt = moment(dateString);

  if (now.diff(updatedAt, "days") < 2) {
    return updatedAt.calendar();
  }
  return `am ${updatedAt.format("Do MMM YYYY")}`;
};

const MessageThreadsContainer = ({
  i18n,
  filter,
  setFilter,
  messageThreads,
  messagesStatistics,
  loadMessages,
  searchParams,
  messageDrafts
}) => {
  const [loading, setLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const messageLabels = useSelector(getMessageLabels);

  const searchRef = useRef(searchParams);

  useEffect(() => {
    if (searchRef.current !== searchParams) {
      searchRef.current = searchParams;
    }
  }, [searchParams]);

  function reloadMessages() {
    setLoading(true);
    loadMessages(searchRef.current)
      .catch(silentHandleApiRequestErrors)
      .catch(error => {
        setErrorLoading(true);
        throw error;
      })
      .then(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    const intervalId = setInterval(reloadMessages, REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    reloadMessages();
  }, [filter]);

  function handleSearch() {
    if (searchTerm) {
      setFilter({ ...filter, query: searchTerm || null });
    } else {
      setFilter(omit(filter, ["query"]));
    }
  }

  function renderMessageSearch() {
    const hasSearchTerm =
      (filter.query || searchTerm) && filter.query !== searchTerm;

    return (
      <Input
        placeholder={
          i18n["messaging.messages_container.placeholder.search_term"]
        }
        fluid
        onChange={(_, { value }) => setSearchTerm(value)}
        defaultValue={searchTerm}
        onKeyPress={e => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        action={{
          icon: hasSearchTerm ? "search" : "refresh",
          onClick: handleSearch,
          color: hasSearchTerm ? "blue" : "grey"
        }}
      />
    );
  }

  const renderMessageLabels = useCallback(
    selected_labels => (
      <div className="flex" style={{ flexWrap: "wrap" }}>
        {selected_labels.map(selected_label => {
          const message_label = messageLabels.find(
            ml => ml.id === selected_label.id
          );
          if (!message_label) return null;
          return (
            <Label
              basic
              size="small"
              key={message_label.id}
              style={{
                lineHeight: "0.3rem",
                marginTop: "3px",
                height: "1.3rem"
              }}
            >
              <Icon name="square" color={message_label.color} />

              {message_label.name}
            </Label>
          );
        })}
      </div>
    ),
    [messageLabels]
  );

  function renderMessageThreadsContent() {
    return (
      <Table
        className="inbox-wrapper threads-list"
        selectable
        verticalAlign="top"
      >
        <Table.Body>
          {messageThreads.map(
            ({
              external_participants: externalParticipants,
              internal_participants: internalParticipants,
              id,
              read,
              subject,
              message_count: messageCount,
              updated_at: updatedAt,
              attachment_count: attachmentCount,
              message_labels
            }) => {
              const allParticipants = externalParticipants.concat(
                internalParticipants
              );
              const primaryParticipant = allParticipants[0] || {};
              const participantCount = allParticipants.length - 1; // ignore primary for count

              return (
                <>
                  <Table.Row
                    verticalAlign="top"
                    onClick={() => browserHistory.push(`/messages/${id}`)}
                    key={id}
                    className={`messageThread ${read ? "read" : "unread"}`}
                  >
                    <Table.Cell collapsing>
                      <Icon
                        name={
                          read ? "envelope open outline" : "envelope outline"
                        }
                      />
                      <If condition={!!messageDrafts[id]}>
                        <Icon name="edit outline" />
                      </If>
                    </Table.Cell>
                    <Table.Cell className="participants" width={3}>
                      <Popup
                        hoverable
                        mouseEnterDelay={POPUP_MOUSE_ENTER_DELAY}
                        mouseLeaveDelay={POPUP_MOUSE_LEAVE_DELAY}
                        trigger={
                          <span>
                            <span className="primary">
                              {primaryParticipant.label}
                            </span>
                            <span className="secondary">
                              <FormattedMessage
                                id="messaging.messages_container.messageThread.participantCount"
                                values={{ participantCount }}
                                defaultMessage={`${participantCount}`}
                              />
                            </span>
                          </span>
                        }
                      >
                        {allParticipants.map(({ label }) => (
                          <span key={label}>
                            {label},<br />
                          </span>
                        ))}
                      </Popup>
                    </Table.Cell>
                    <Table.Cell className="subject" width={10}>
                      <Popup
                        hoverable
                        mouseEnterDelay={POPUP_MOUSE_ENTER_DELAY}
                        mouseLeaveDelay={POPUP_MOUSE_LEAVE_DELAY}
                        trigger={
                          <span>
                            <MessageBodyV1 text={subject} whiteSpace="unset" />
                          </span>
                        }
                      >
                        <span>
                          <MessageBodyV1 text={subject} whiteSpace="unset" />
                        </span>
                      </Popup>
                    </Table.Cell>
                    <Table.Cell collapsing className="date" textAlign="right">
                      {displayDate(updatedAt)}
                      <div className="meta">
                        {attachmentCount > 0 && (
                          <div>
                            <Icon name="attach" />
                            <FormattedMessage
                              id="messaging.messages_container.messageThread.attachmentCount"
                              values={{ attachmentCount }}
                              defaultMessage={`${attachmentCount}`}
                            />
                          </div>
                        )}
                        {messageCount > 0 && (
                          <div>
                            <Icon name="conversation" />
                            <FormattedMessage
                              id="messaging.messages_container.messageThread.messageCount"
                              values={{ messageCount }}
                              defaultMessage={`${messageCount}`}
                            />
                          </div>
                        )}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                  <If condition={message_labels.length}>
                    <Table.Row cellAs="div" className="join">
                      <Table.Cell collapsing as="th" />
                      <Table.Cell collapsing as="th" width={15} colspan="3">
                        {renderMessageLabels(message_labels)}
                      </Table.Cell>
                    </Table.Row>
                  </If>
                </>
              );
            }
          )}
        </Table.Body>
      </Table>
    );
  }

  function renderMessageThreadsEmpty() {
    const message = errorLoading
      ? "messaging.messages_container.messages.error_loading_messages"
      : "messaging.messages_container.messages.no_messages_found";
    return (
      <Message warning={errorLoading}>
        <Icon name="inbox" />
        <Message.Content>
          <FormattedMessage
            id={message}
            defaultValue="Es wurden keine Nachrichten gefunden."
          />
        </Message.Content>
      </Message>
    );
  }

  function handlePageChange(e, { activePage }) {
    setFilter({ ...filter, page: activePage });
  }

  function renderPagination() {
    const total = Math.ceil((messagesStatistics.total || 0) / MAX_THREADS);

    return (
      <Pagination
        disabled={total < 2}
        onPageChange={handlePageChange}
        activePage={filter.page || 1}
        totalPages={total}
      />
    );
  }

  return (
    <>
      <Segment>{renderMessageSearch()}</Segment>
      <Segment loading={loading}>
        {messageThreads.length === 0 && renderMessageThreadsEmpty()}
        {messageThreads.length > 0 && renderMessageThreadsContent()}
      </Segment>
      <Segment textAlign="center">{renderPagination()}</Segment>
    </>
  );
};

MessageThreadsContainer.propTypes = {
  i18n: I18nShape.isRequired,
  setFilter: func.isRequired,
  loadMessages: func.isRequired,
  filter: shape({ id: string, page: number, project: string }).isRequired,
  messageThreads: arrayOf(MessageThreadShape).isRequired,
  messagesStatistics: shape({
    own_total: number,
    total: number,
    unassigned: number,
    unread_own: number,
    unread_total: number
  }).isRequired,
  searchParams: shape({
    page: number,
    project: string,
    load_unassigned: bool,
    message_type: string
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  messageDrafts: object
};

MessageThreadsContainer.defaultProps = {
  messageDrafts: {}
};

const mapStateToProps = (state, props) => {
  return {
    i18n: getI18N(state),
    filter: getMessagesFilter(state),
    messageThreads: getMessages(state),
    messageDrafts: keyBy(
      getMessageDraftsForThreads(state),
      "message_thread_id"
    ),
    searchParams: { ...getSearchParams(state), ...props.customParams },
    messagesStatistics: getMessagesStatistics(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: setMessagesFilter(dispatch),
    loadMessages: params => {
      const resource = new MessagesResource(dispatch);
      return resource.fetchAll({
        ...params,
        limit: MAX_THREADS
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageThreadsContainer);
