/* eslint-disable no-param-reassign */
import PropTypes from "prop-types";
import React, { useState, useCallback, useRef } from "react";
import { Container, Grid, Header, Button, Modal } from "semantic-ui-react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { If } from "shared/components/elements/Conditions";
import { Input, Form, Checkbox } from "formik-semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import ProjectRolesResource from "builder_portal/actions/projectRolesActions";
import Growl from "../../../actions/growlActions";

const ProjectRoleDialog = ({ model, trigger }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const refResetForm = useRef();
  const [open, setOpen] = useState(false);

  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const onSubmit = useCallback(
    values => {
      const resource = new ProjectRolesResource(dispatch);
      // append the id to the values if it exists
      if (model?.id) {
        values.id = model?.id;
      }
      if (!values.allow_access_activities)
        values.allow_access_all_activities = false;
      if (!values.allow_access_all_units)
        values.allow_access_edit_units = false;
      if (!values.allow_access_all_units || !values.allow_access_all_activities)
        values.allow_access_statistics = false;

      return resource
        .save(values)
        .then(() => {
          new Growl(dispatch).success(
            "message.success.title",
            "meta.states.saving"
          );
          return resource.fetchAll().then(handleClose);
        })
        .catch(() => {
          new Growl(dispatch).error(
            "message.error.title",
            "meta.confirmations.changesNotSaved"
          );
        });
    },
    [model]
  );

  return (
    <Formik
      initialValues={{
        ...model
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit, resetForm, values }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            size="small"
            closeOnEscape
            closeOnDimmerClick
            closeIcon
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            trigger={trigger}
          >
            <Header as="h2">
              {model?.id ? (
                <FormattedMessage
                  id="projectRoles.dialog.title.edit"
                  default="projectRoles.dialog.title.edit"
                />
              ) : (
                <FormattedMessage
                  id="projectRoles.dialog.title.add"
                  default="projectRoles.dialog.title.add"
                />
              )}
            </Header>
            <Modal.Content scrolling>
              <Form id="projectRolesBackendForm">
                <Container>
                  <Form>
                    <Container>
                      <Grid>
                        <Grid.Column width={16}>
                          <Input
                            fluid
                            placeholder={intl.formatMessage({
                              id: "projectRoles.dialog.name"
                            })}
                            name="name"
                            label={intl.formatMessage({
                              id: "projectRoles.dialog.name"
                            })}
                          />
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Input
                            fluid
                            placeholder={intl.formatMessage({
                              id: "projectRoles.dialog.descriptionPlaceholder"
                            })}
                            name="description"
                            label={intl.formatMessage({
                              id: "projectRoles.dialog.description"
                            })}
                          />
                        </Grid.Column>
                        <Grid.Column
                          width={16}
                          style={{ marginBottom: "-10px" }}
                        >
                          <Header as="h4">
                            <FormattedMessage id="projectRoles.dialog.permissions" />
                          </Header>
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_perspective_onboarding"
                            label={intl.formatMessage({
                              id:
                                "projectRoles.dialog.allowPerspectiveOnboarding"
                            })}
                          />
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_perspective_sales"
                            label={intl.formatMessage({
                              id: "projectRoles.dialog.allowPerspectiveSales"
                            })}
                          />
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_access_activities"
                            label={intl.formatMessage({
                              id: "projectRoles.dialog.allowAccessActivities"
                            })}
                          />
                        </Grid.Column>
                        <If condition={!!values.allow_access_activities}>
                          <Grid.Column width={16}>
                            <Checkbox
                              name="allow_access_all_activities"
                              label={intl.formatMessage({
                                id:
                                  "projectRoles.dialog.allowAccessAllActivities"
                              })}
                            />
                          </Grid.Column>
                        </If>
                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_access_all_units"
                            label={intl.formatMessage({
                              id: "projectRoles.dialog.allowAccessAllUnits"
                            })}
                          />
                        </Grid.Column>
                        <If condition={!!values.allow_access_all_units}>
                          <Grid.Column width={16}>
                            <Checkbox
                              name="allow_access_edit_units"
                              label={intl.formatMessage({
                                id: "projectRoles.dialog.allowAccessEditUnits"
                              })}
                            />
                          </Grid.Column>
                        </If>
                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_access_deadlines"
                            label={intl.formatMessage({
                              id: "projectRoles.dialog.allowAccessDeadlines"
                            })}
                          />
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_access_documents"
                            label={intl.formatMessage({
                              id: "projectRoles.dialog.allowAccessDocuments"
                            })}
                          />
                        </Grid.Column>
                        <If
                          condition={
                            !!values.allow_access_all_activities &&
                            !!values.allow_access_all_units
                          }
                        >
                          <Grid.Column width={16}>
                            <Checkbox
                              name="allow_access_statistics"
                              label={intl.formatMessage({
                                id: "projectRoles.dialog.allowAccessStatistics"
                              })}
                            />
                          </Grid.Column>
                        </If>
                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_access_all_messages"
                            label={intl.formatMessage({
                              id: "projectRoles.dialog.allowAccessAllMessages"
                            })}
                          />
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_access_edit_project"
                            label={intl.formatMessage({
                              id: "projectRoles.dialog.allowAccessEditProject"
                            })}
                          />
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_access_buyers"
                            label={intl.formatMessage({
                              id: "projectRoles.dialog.allowAccessBuyers"
                            })}
                          />
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_access_contractors"
                            label={intl.formatMessage({
                              id: "projectRoles.dialog.allowAccessContractors"
                            })}
                          />
                        </Grid.Column>
                      </Grid>
                    </Container>
                  </Form>
                </Container>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button basic disabled={isSubmitting} onClick={handleClose}>
                <FormattedMessage id="meta.actions.cancel" />
              </Button>
              <Button
                primary
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                <FormattedMessage id="meta.actions.save" />
              </Button>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

ProjectRoleDialog.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    allow_perspective_onboarding: PropTypes.bool.isRequired,
    allow_perspective_sales: PropTypes.bool.isRequired,
    allow_access_activities: PropTypes.bool.isRequired,
    allow_access_all_units: PropTypes.bool.isRequired,
    allow_access_deadlines: PropTypes.bool.isRequired,
    allow_access_documents: PropTypes.bool.isRequired,
    allow_access_statistics: PropTypes.bool.isRequired,
    allow_access_all_activities: PropTypes.bool.isRequired,
    allow_access_edit_units: PropTypes.bool.isRequired,
    allow_access_all_messages: PropTypes.bool.isRequired,
    allow_access_edit_project: PropTypes.bool.isRequired,
    allow_access_buyers: PropTypes.bool.isRequired,
    allow_access_contractors: PropTypes.bool.isRequired
  }),
  trigger: PropTypes.node
};

ProjectRoleDialog.defaultProps = {
  trigger: undefined,
  model: undefined
};

export default ProjectRoleDialog;
