import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import { ProjectDataExportsResource } from "builder_portal/actions/projectDataExportsActions";
import { getProjectsSorted, getAccount } from "shared/selectors";
import { Button, Loader, Segment } from "semantic-ui-react";
import { getProjectDataExports } from "shared/selectors/projectDataExports";
import StickyMenu from "shared/components/menus/StickyMenu";
import { If } from "shared/components/elements/Conditions";
import { keyBy } from "lodash";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import useInterval from "builder_portal/hooks/useInterval";

const propName = id => `project${id}`;

const ProjectDataExport = () => {
  const dispatch = useDispatch();
  const projects = useSelector(getProjectsSorted);
  const account = useSelector(getAccount);
  const projectDataExports = useSelector(getProjectDataExports);

  const [project, setProject] = useState(null);
  const [requestSent, setRequestSent] = useState({
    [propName(project?.id)]: false
  });
  const [isLoading, setLoading] = useState(false);
  const [lastId, setLastId] = useState();

  useEffect(() => {
    if (projects?.length && !project) setProject(projects[0]);
  }, [projects]);

  const projectChangeHandler = item => {
    setProject(item);
  };

  useEffect(() => {
    new ProjectsResource(dispatch).fetchAll();
  }, []);

  useEffect(() => {
    if (project?.id && project.id !== projectDataExports[0]?.project_id) {
      setLoading(true);
      new ProjectDataExportsResource(dispatch, project.id)
        .fetchAll()
        .then(() => setLoading(false));
    }
  }, [project]);

  useInterval(() => {
    if (requestSent[propName(project?.id)])
      new ProjectDataExportsResource(dispatch, project.id).fetchAll();
  }, 5000);

  useEffect(() => {
    if (
      projectDataExports[0]?.id !== lastId &&
      (projectDataExports[0]?.status === "completed" ||
        projectDataExports[0]?.status === "failed")
    ) {
      setRequestSent({ ...requestSent, [propName(project?.id)]: false });
    }
  }, [projectDataExports]);

  const handleStartExport = () => {
    new ProjectDataExportsResource(dispatch, project.id).save({});
    setRequestSent({ ...requestSent, [propName(project?.id)]: true });
    setLastId(projectDataExports[0]?.id);
  };

  return (
    <>
      <StickyMenu
        stickyItem={null}
        allItems={keyBy(projects, "id")}
        accountId={account?.data?.account?.id}
        onSelectedItemChange={projectChangeHandler}
        storeKey="projectDataExport"
      />
      <Segment data-component="project-settings">
        <If condition={isLoading}>
          <Loader active />
        </If>
        <If condition={!isLoading}>
          <If condition={!!project}>
            <If condition={!requestSent[propName(project?.id)]}>
              <Button color="green" onClick={handleStartExport}>
                <FormattedMessage
                  id="dataExport.button.label"
                  default="Start export"
                />
              </Button>
            </If>
            <If condition={requestSent[propName(project?.id)]}>
              <FormattedMessage
                id="dataExport.startMessage"
                default="Der Export wurde gestartet"
              />
              <If condition={requestSent[propName(project?.id)]}>
                <Loader active />
              </If>
            </If>
            <div style={{ marginTop: "20px" }}>
              {projectDataExports.map(dataExport => {
                return (
                  <div key={dataExport.id} style={{ marginTop: "7px" }}>
                    <If condition={dataExport.status === "completed"}>
                      <form action={dataExport.file_url}>
                        <Button basic style={{ minWidth: "300px" }}>
                          {dataExport.file_file_name || "no name"}
                        </Button>
                      </form>
                    </If>
                    <If
                      condition={
                        dataExport.status === "exporting" ||
                        dataExport.status === "pending"
                      }
                    >
                      <div>
                        <Button disabled style={{ minWidth: "300px" }}>
                          {dataExport.status}{" "}
                          {moment(dataExport.updated_at).format("LLL")}
                        </Button>
                      </div>
                    </If>
                    <If condition={dataExport.status === "failed"}>
                      <div>
                        <Button
                          disabled
                          color="red"
                          style={{ minWidth: "300px" }}
                        >
                          {dataExport.status}{" "}
                          {moment(dataExport.updated_at).format("LLL")}
                        </Button>
                      </div>
                    </If>
                  </div>
                );
              })}
            </div>
          </If>
        </If>
      </Segment>
    </>
  );
};

export default ProjectDataExport;
