import { cloneDeep } from "lodash";
import { string, number, shape, arrayOf, func, oneOf, node } from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Form, Modal } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { I18nShape } from "shared/shapes/i18n.shape";
import FloatInput from "shared/components/forms/FloatInput";
import silentHandleApiRequestErrors from "shared/helpers/silentHandleApiRequestErrors";

class TradesDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      open: false,
      trade: {
        defaultMargin: 0,
        ...cloneDeep(props.trade)
      }
    };
  }

  handleSave = ({ id, label, defaultMargin }) => {
    const { onSave } = this.props;
    const sanitizedDefaultMargin = defaultMargin === "" ? 0 : defaultMargin;
    this.setState({ loading: true });
    onSave({
      id: id.toUpperCase(),
      label,
      defaultMargin: sanitizedDefaultMargin
    })
      .then(() => {
        this.closeDialog();
      })
      .catch(silentHandleApiRequestErrors)
      .catch(() => {
        this.closeDialog();
      });
  };

  closeDialog = () => {
    const { trade } = this.props;
    this.setState({
      open: false,
      loading: false,
      trade: cloneDeep(trade)
    });
  };

  render() {
    const { i18n, mode, trade, trades, button } = this.props;
    const { loading, open, trade: stateTrade } = this.state;

    const tradeIds = trades.map(t => {
      return t.id;
    });
    const FormFactory = new FormDefinition({
      fields: [
        {
          id: "id",
          label: "trades.attributes.id.label",
          rule: value => {
            if (!value) {
              return true;
            }

            if (trade.id !== value && tradeIds.indexOf(value) >= 0) {
              return true;
            }

            if (value.match(/^[A-Z]*$/) === null) return true;

            return value.length !== 3;
          },
          message: "trades.attributes.id.error"
        },
        {
          id: "label",
          label: "trades.attributes.label.label",
          rule: "isRequired"
        },
        {
          id: "defaultMargin",
          label: "%",
          placeholder: "20"
        }
      ]
    });

    const form = FormFactory.create(stateTrade, i18n, {
      onChange: nextTrade => this.setState({ trade: nextTrade })
    });

    form.fields.id.props.readOnly = mode === "EDIT";
    form.fields.defaultMargin.props.labelPosition = "right";
    if (mode === "CREATE") form.fields.defaultMargin.props.value = 20;

    return (
      <Modal
        size="small"
        closeOnEscape
        closeOnDimmerClick
        closeIcon
        trigger={button}
        open={open}
        data-component="TradesDialog"
        onOpen={() => this.setState({ open: true })}
        onClose={() => this.setState({ open: false })}
      >
        <Modal.Header>
          <FormattedMessage id="trades.title.one" default="trades.title.one" />
        </Modal.Header>
        <Modal.Content>
          <Form id="trade" data-component="trade">
            <Form.Field>
              <Field component="Input" {...form.fields.id} />
            </Form.Field>
            <Form.Field>
              <Field component="Input" {...form.fields.label} />
            </Form.Field>
            <Form.Field>
              {/* Is associated by SemanticUI inside the FormField */}
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                <FormattedMessage
                  id="trades.attributes.defaultMargin.label"
                  defaultMessage="Standard Aufschlag"
                />
              </label>
              <Field component={FloatInput} {...form.fields.defaultMargin} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="submit"
            positive
            loading={loading}
            content={i18n["meta.actions.save"]}
            onClick={form.handleSubmit(this.handleSave.bind(this))}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

TradesDialog.propTypes = {
  i18n: I18nShape.isRequired,
  trade: shape({
    id: string,
    label: string,
    defaultMargin: number
  }).isRequired,
  trades: arrayOf(
    shape({
      id: string,
      label: string,
      defaultMargin: number
    })
  ).isRequired,
  button: node.isRequired,
  onSave: func.isRequired,
  mode: oneOf(["CREATE", "EDIT"]).isRequired
};

export default TradesDialog;
