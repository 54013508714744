import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
  Button,
  Form,
  Header,
  Icon,
  Image,
  Segment,
  SegmentGroup,
  Grid
} from "semantic-ui-react";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import FileUploader from "builder_portal/components/dropzone/FileUploader";
import Field from "shared/components/forms/FieldComponent";
import { get } from "lodash";
import { connect } from "react-redux";
import { getGravatarUrl } from "react-awesome-gravatar";
import ProfileResource from "../../actions/profileActions";
import logoutAction from "../../actions/logoutAction";
import { getSalutations, getTitles } from "../../../shared/selectors/account";
import MembershipReplacement from "./MembershipReplacement";

const FormFactory = new FormDefinition({
  fields: [
    {
      id: "image"
    },
    {
      id: "salutation",
      label: "meta.form_of_address.salutation.label",
      autoComplete: "off"
    },
    {
      id: "title",
      label: "meta.form_of_address.title.label",
      autoComplete: "off"
    },
    {
      id: "first_name",
      label: "user.attributes.first_name.label",
      rule: "isRequired"
    },
    {
      id: "last_name",
      label: "user.attributes.last_name.label",
      rule: "isRequired"
    },
    {
      id: "position",
      label: "user.attributes.position.label"
    },
    {
      id: "phone",
      label: "user.attributes.phone.label"
    },
    {
      id: "mobile",
      label: "user.attributes.mobile.label"
    },
    {
      id: "fax",
      label: "user.attributes.fax.label"
    }
  ]
});

const NotificationFormFactory = new FormDefinition({
  fields: [
    {
      id: "daily_digest",
      label: "user.attributes.daily_digest.label",
      control: "Checkbox"
    },
    {
      id: "outbound_bcc",
      label: "user.attributes.outbound_bcc.label",
      control: "Checkbox"
    },
    {
      id: "inbound_fwd",
      label: "user.attributes.inbound_fwd.label",
      control: "Checkbox"
    }
  ]
});

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, user: null, profile: null };
  }

  static renderEmpty() {
    return <h1>Profil wird geladen.</h1>;
  }

  componentDidMount() {
    const { resource } = this.props;
    resource.get(true);
  }

  componentDidUpdate(prevProps) {
    const { pageContent } = this.props;

    if (prevProps.pageContent !== pageContent) {
      const data = {
        user: get(pageContent, "profile.user"),
        profile: get(pageContent, "profile"),
        notifications: get(pageContent, "profile.user.notification_config")
      };
      this.writeProfileToState(data);
    }
  }

  onSubmit(values) {
    const { resource } = this.props;
    this.setState({ isLoading: true });

    delete values.notification_config;

    resource.save(values).then(() => {
      this.setState({ isLoading: false });
    });
  }

  onSubmitNotifications(values) {
    const { resource } = this.props;
    this.setState({ isLoading: true });

    resource.save({ notification_config: values }).then(() => {
      this.setState({ isLoading: false });
    });
  }

  writeProfileToState = data => {
    this.setState({
      user: data.user,
      profile: data.profile,
      notifications: data.notifications
    });
  };

  switchTo = membership => {
    return () => {
      const { resource } = this.props;
      resource.save({ account_id: membership.id }).then(response => {
        if (response.status === 200) {
          window.location.reload();
        }
      });
    };
  };

  handleImage = file => {
    const reader = new FileReader();
    reader.onload = () => {
      const binaryStr = reader.result;
      this.setState(prev => ({
        ...prev,
        user: { ...prev.user, image: binaryStr }
      }));
    };
    reader.readAsDataURL(file);
  };

  handleDeleteImage = () => {
    this.setState(prev => ({
      ...prev,
      user: { ...prev.user, image: null, destroy_image: true, image_url: "" }
    }));
  };

  renderAccounts(profile) {
    if (profile.user.group !== "admin") {
      return null;
    }
    const buttons = profile.account_memberships.map(membership => {
      return (
        <Button key={membership.id} onClick={this.switchTo(membership)}>
          <Icon name="book" />
          &nbsp;{membership.name}
        </Button>
      );
    });
    return (
      <div style={{ marginTop: "20px" }}>
        <Segment attached="top">
          <Header as="h4">Account Wechsel</Header>
        </Segment>
        <Segment attached>
          <p>
            Dein aktueller Account ist: <b>{profile.account.name}</b>
          </p>
          {buttons}
        </Segment>
      </div>
    );
  }

  renderResetLocalStorage() {
    const { logout } = this.props;
    return (
      <SegmentGroup>
        <Header as="h4" attached="top">
          Lokale Einstellungen
        </Header>
        <Segment attached>
          <Button onClick={() => logout(true)} negative>
            <Icon name="power" />
            Reset
          </Button>
        </Segment>
      </SegmentGroup>
    );
  }

  renderNotifications(notifications) {
    const { i18n } = this.props;
    const form = NotificationFormFactory.create(notifications, i18n, {
      onChange: data => {
        this.setState({ notifications: data });
      }
    });

    return (
      <div style={{ marginTop: "20px" }}>
        <Segment attached="top">
          <Header as="h4">E-Mail Benachrichtigungen</Header>
        </Segment>
        <Segment attached>
          <Form
            id="profile"
            onSubmit={form.handleSubmit(this.onSubmitNotifications.bind(this))}
            data-component="profileForm"
          >
            <Form.Field>
              <Field component="Checkbox" {...form.fields.daily_digest} />
            </Form.Field>

            <Form.Field>
              <Field component="Checkbox" {...form.fields.outbound_bcc} />
            </Form.Field>

            <Form.Field>
              <Field component="Checkbox" {...form.fields.inbound_fwd} />
            </Form.Field>

            <Form.Field>
              <Form.Button
                type="submit"
                color="green"
                loading={this.state.isLoading}
                id="save"
              >
                <Icon name="save" />
                <FormattedMessage
                  id="meta.actions.save"
                  defaultMessage="meta.actions.save"
                />
              </Form.Button>
            </Form.Field>
          </Form>
        </Segment>
      </div>
    );
  }

  renderProfile(user) {
    const { i18n } = this.props;
    const form = FormFactory.create(user, i18n, {
      onChange: data => {
        this.setState({ user: data });
      }
    });

    const profileUrl =
      user.image_url || getGravatarUrl(user.email, { default: "mp" });
    const avatar = profileUrl ? <Image src={profileUrl} as="i" avatar /> : null;

    return (
      <div>
        <Segment attached="top">
          <Header as="h4">
            {avatar} Mein Benutzerprofil ({user.email})
          </Header>
        </Segment>
        <Segment attached>
          <Form
            id="profile"
            onSubmit={form.handleSubmit(this.onSubmit.bind(this))}
            data-component="profileForm"
          >
            <Grid>
              <Grid.Column
                width={8}
                verticalAlign="top"
                style={{ marginBottom: "10px" }}
              >
                <Form.Field>
                  <Field
                    component="Select"
                    {...form.fields.salutation}
                    props={{
                      ...form.fields.salutation.props,
                      clearable: true,
                      placeholder: this.props.i18n[
                        "meta.form_of_address.salutation.nil"
                      ],
                      options: getSalutations.map(key => {
                        return {
                          key,
                          value: key,
                          text: this.props.i18n[
                            `meta.form_of_address.salutation.${key || "nil"}`
                          ]
                        };
                      })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Field
                    component="Select"
                    {...form.fields.title}
                    props={{
                      ...form.fields.title.props,
                      clearable: true,
                      placeholder: this.props.i18n[
                        "meta.form_of_address.title.nil"
                      ],
                      options: getTitles.map(key => {
                        return {
                          key,
                          value: key,
                          text: this.props.i18n[
                            `meta.form_of_address.title.${key || "nil"}`
                          ]
                        };
                      })
                    }}
                  />
                </Form.Field>

                <Form.Field>
                  <Field component="Input" {...form.fields.first_name} />
                </Form.Field>

                <Form.Field>
                  <Field component="Input" {...form.fields.last_name} />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={8}>
                <label htmlFor="image" style={{ fontWeight: 700, margin: 0 }}>
                  <FormattedMessage id="user.attributes.image.label" />
                </label>
                <FileUploader
                  id="image"
                  fileType="image"
                  name="image"
                  previewImageUrl={user.image_url || user.image}
                  handleFile={this.handleImage}
                  handleDelete={this.handleDeleteImage}
                  style={{ margin: 0 }}
                />
              </Grid.Column>
            </Grid>
            <Form.Group widths="equal">
              <Form.Field>
                <Field component="Input" {...form.fields.position} />
              </Form.Field>
              <Form.Field>
                <Field component="Input" {...form.fields.phone} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Field component="Input" {...form.fields.mobile} />
              </Form.Field>
              <Form.Field>
                <Field component="Input" {...form.fields.fax} />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Form.Button
                type="submit"
                color="green"
                loading={this.state.isLoading}
                id="save"
              >
                <Icon name="save" />
                <FormattedMessage
                  id="meta.actions.save"
                  defaultMessage="meta.actions.save"
                />
              </Form.Button>
            </Form.Field>
          </Form>
        </Segment>
      </div>
    );
  }

  render() {
    const { user, profile, notifications } = this.state;

    if (user && profile) {
      return (
        <div>
          {this.renderProfile(user)}
          <MembershipReplacement />
          {this.renderNotifications(notifications)}
          {this.renderResetLocalStorage()}
          {this.renderAccounts(profile)}
        </div>
      );
    }
    return Profile.renderEmpty();
  }
}

Profile.propTypes = {
  resource: PropTypes.object,
  pageContent: PropTypes.object,
  i18n: PropTypes.object,
  logout: PropTypes.func
};

const mapStateToProps = state => ({
  i18n: state.i18n,
  profile: state.account,
  pageContent: state.pageContent
});

const mapDispatchToProps = dispatch => ({
  resource: new ProfileResource(dispatch),
  logout: new logoutAction(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
