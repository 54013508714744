import React, { Component } from "react";
import { connect } from "react-redux";
import {
  arrayOf,
  func,
  instanceOf,
  number,
  oneOf,
  shape,
  string
} from "prop-types";
import { FormattedMessage } from "react-intl";
import { compact, get } from "lodash";
import { Form, Button, Icon, Modal } from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import FloatInput from "shared/components/forms/FloatInput";
import { ProductMetaDataResource } from "builder_portal/actions/productActions";
import ColorInputWithHexField from "shared/components/forms/ColorInputWithHexField";
import { getMasterProductOptions } from "shared/selectors/product";
import {
  getProductCatalogs,
  getProductCatalogOptions
} from "shared/selectors/productCatalog";
import { ProductCatalogShape } from "shared/shapes/productCatalog.shape";
import { ProductGroupShape } from "shared/shapes";
import { getProductGroups } from "shared/selectors/unit/productData";
import Field from "../../../shared/components/forms/FieldComponent";
import { FormDefinition } from "../../../shared/components/forms/FormDefinition";
import { getAccount, getI18N } from "../../../shared/selectors";
import { getTagOptions } from "../../helpers/getTagOptions";
import TradesFormField from "../forms/TradesFormField";
import { ProductShape, I18nShape } from "../../../shared/shapes";
import { ProductResource } from "../../actions/productActions";
import { SupplierShape } from "../../../shared/shapes/supplier.shape";
import { Account } from "../../../shared/models/account";
import getProductTypeOptions from "../../selectors/productTypeOptions";
import OptionsShape from "../../../shared/shapes/options.shape";
import ProductFieldValues from "./productFieldValues/ProductFieldValues";
import FeatureToggleActive from "../../../shared/components/elements/FeatureToggleActive";

const productWithSupplierPrice = product => {
  if (!product.supplier_price && product.price_in_cents)
    return { ...product, supplier_price: product.price_in_cents / 100 };
  return product;
};

class CustomProductForm extends Component {
  constructor(props) {
    super(props);
    // this form is called from various places this product_catalog_id is retrieved differently
    const productGroup = props.productGroups.find(
      pg => pg.id === props.productGroupId
    );
    const productGroupProductCatalogId = productGroup?.product_catalog_id;

    const urlProductCatalogId = parseInt(
      new URLSearchParams(window.location.search).get("product_catalog_id"),
      10
    );

    const modelCatalogId = props.model.product_catalog_id;

    const product_catalog_id =
      modelCatalogId || urlProductCatalogId || productGroupProductCatalogId;

    this.state = {
      newSupplier: false,
      product: { ...productWithSupplierPrice(props.model), product_catalog_id },
      isLoading: false,
      productGroupPresent: !!productGroupProductCatalogId
    };
  }

  componentDidMount() {
    this.onInit(this.props);
    const { product } = this.state;
    const { resources } = this.props;

    this.setState({
      product: {
        ...product,
        supplier: product.supplier || "none"
      }
    });

    if (product?.id) {
      resources.products.productsByManufacturerSeries(
        product.id,
        product.supplier_id,
        product.series
      );
    }
  }

  onInit(props) {
    this.setState({ product: props.model });
  }

  getSuppliers() {
    const { suppliers } = this.props;
    return suppliers.map(item => {
      return {
        key: item.name,
        value: item.name,
        text: `${item.name} (${item.product_count})`
      };
    });
  }

  onProductFieldValuesChange = newProductFieldValues => {
    const { product } = this.state;
    product.product_field_values = newProductFieldValues;
    product.product_field_values_attributes = newProductFieldValues;
    this.setState({ product });
  };

  getProductTags() {
    const { account } = this.props;
    const { taxonomies } = account.data.account.config;

    return getTagOptions(taxonomies);
  }

  onSave = values => {
    const {
      context,
      resources,
      handleUpdate,
      handleClose,
      handleSave,
      setShowCloseIcon,
      productGroupId,
      productMetaDataResource,
      account,
      mode,
      productCatalogs
    } = this.props;
    const acountId = account.data.account.id;
    this.setState({ isLoading: true });

    const { supplier } = values;

    if (typeof setShowCloseIcon === "function") setShowCloseIcon(true);
    const product = {
      ...values,
      supplier: supplier === "none" ? null : supplier,
      product_field_values: compact(
        values?.product_field_values?.map(item => {
          return {
            ...item,
            account_id: acountId
          };
        })
      ),
      product_field_values_attributes: compact(
        values?.product_field_values_attributes?.map(item => {
          return {
            ...item,
            account_id: acountId
          };
        })
      ),
      product_catalog_id: values.product_catalog_id || productCatalogs[0]?.id
    };

    if (context === "ProductImporter" && handleSave) {
      return handleSave(product).then(() => {
        productMetaDataResource.fetchAll();
      });
    }

    if (mode === "clone") {
      delete product.id;
    }

    return resources.products.save(product).then(response => {
      if (productGroupId) {
        // we're in a project's product group context ...
        const productId = get(response, "data.product.id");
        const payload = { product_id: productId, price: 0.0 };
        const resource = resources.pricesFactory(productGroupId);

        // ... so we add the newly created product to the given product group
        resource.save(payload).then(() => {
          handleUpdate().then(() => {
            this.setState({ isLoading: false });
            if (handleClose) {
              handleClose();
            }

            return handleUpdate(response.data.product).then(() => {
              productMetaDataResource.fetchAll();
            });
          });
        });
      } else {
        this.setState({ isLoading: false });
        if (handleClose) {
          handleClose();
        }
        return handleUpdate(response.data.product).then(() => {
          productMetaDataResource.fetchAll();
        });
      }
    });
  };

  renderSupplierDropDown(form) {
    const { i18n } = this.props;

    const withoutSupplier = {
      key: "none",
      value: "none",
      text: i18n["product.attributes.supplier.none"]
    };

    form.fields.supplier.props.options = [
      withoutSupplier,
      ...this.getSuppliers()
    ];
    form.fields.supplier.props.search = true;
    form.fields.supplier.props.selection = true;

    return (
      <div>
        <Field component="Select" {...form.fields.supplier} />
        <div style={{ margin: "5px 3px" }}>
          <a
            role="button"
            onClick={() => {
              this.setState({ newSupplier: true });
            }}
          >
            <Icon name="plus" />
            &nbsp;
            <u>
              <FormattedMessage
                id="product.actions.addSupplier.label"
                default="product.actions.addSupplier.label"
              />
            </u>
          </a>
        </div>
      </div>
    );
  }

  renderSupplierInput(form) {
    const { context } = this.props;
    let options = { ...form.fields.supplier };
    if (context === "ProductImporter") {
      options = {
        ...form.fields.supplier,
        props: { ...form.fields.supplier.props, readOnly: true }
      };
    }
    return <Field component="Input" {...options} />;
  }

  render() {
    const {
      i18n,
      account,
      productTypes,
      handleClose,
      context,
      masterProductOptions,
      mode,
      productCatalogs,
      productCatalogOptions
    } = this.props;
    const { product, newSupplier, isLoading, productGroupPresent } = this.state;

    const productCatalogsCount = productCatalogs.length;
    const suppliers = this.getSuppliers();
    const FormFactory = new FormDefinition({
      fields: [
        {
          id: "name",
          label: "product.attributes.name.label",
          rule: "isRequired",
          autoComplete: "off",
          autoFocus: true
        },
        {
          id: "display_name",
          label: "product.attributes.display_name.label",
          autoComplete: "off"
        },
        {
          id: "display_hint",
          label: "product.attributes.display_hint.label",
          placeholder: product.default_display_hint,
          autoComplete: "off"
        },
        {
          id: "description",
          label: "product.attributes.description.label",
          autoComplete: "off"
        },
        {
          id: "technical_description",
          label: "product.attributes.technical_description.label",
          placeholder: compact([
            compact([
              product.supplier,
              product.name,
              product.series,
              product.sku
            ]).join(", "),
            product.description
          ]).join("\n\n"),
          autoComplete: "off"
        },
        {
          id: "supplier",
          label: !newSupplier
            ? "product.attributes.supplier.label"
            : "product.actions.addSupplier.label",
          placeholder: "product.attributes.supplier.placeholder",
          autoFocus: newSupplier,
          rule: "isRequired"
        },
        {
          id: "series",
          label: "product.attributes.series.label",
          rule:
            product.supplier && product.supplier !== "none"
              ? "isRequired"
              : null
        },
        {
          id: "sku",
          label: "product.attributes.sku.label",
          autoComplete: "off",
          rule:
            product.supplier && product.supplier !== "none"
              ? "isRequired"
              : null
        },
        {
          id: "custom_product_code",
          label: "product.attributes.custom_product_code.label",
          autoComplete: "off"
        },
        {
          id: "supplier_price",
          label: "€",
          autoComplete: "off"
        },
        {
          id: "unit_price",
          label: "€",
          autoComplete: "off"
        },
        {
          id: "product_tags",
          label: "Tags",
          placeholder: "product.attributes.product_tags.label",
          default: []
        },
        {
          id: "trades",
          label: "product_group.attributes.trades.label",
          default: [],
          rule: "isRequired"
        },
        {
          id: "product_type_ids",
          label: "product.attributes.product_type.label",
          rule: "isRequired"
        },
        {
          id: "supplier_url",
          label: "product.attributes.supplier_url.label"
        },
        {
          id: "vr_model_url",
          label: "product.attriproductCatalogsCountbutes.vr_model_url.label"
        },
        {
          id: "product_field_values",
          label: "product.attributes.product_field_values.label",
          default: []
        },
        {
          id: "color_code",
          label: "product.attributes.color_code.label"
        },
        {
          id: "master_product_id",
          label: "product.attributes.master_product_id.label"
        },
        {
          id: "product_catalog_id",
          label: "product.attributes.product_catalog_id.label"
        }
      ]
    });

    const form = FormFactory.create(product, i18n, {
      onChange: nextProduct => {
        this.setState({ product: nextProduct });
      }
    });

    form.fields.description.props = {
      ...form.fields.description.props,
      rows: 2
    };
    form.fields.technical_description.props = {
      ...form.fields.technical_description.props,
      rows: 4
    };

    form.fields.product_tags.props = {
      ...form.fields.product_tags.props,
      multiple: true,
      closeOnChange: true,
      search: true,
      options: this.getProductTags(),
      upward: true
    };

    form.fields.product_type_ids.props = {
      ...form.fields.product_type_ids.props,
      closeOnChange: true,
      search: true,
      options: productTypes,
      multiple: true
    };

    form.fields.master_product_id.props = {
      ...form.fields.master_product_id.props,
      closeOnChange: true,
      search: true,
      options: masterProductOptions,
      upward: true,
      clearable: true
    };

    form.fields.product_catalog_id.props = {
      ...form.fields.product_catalog_id.props,
      closeOnChange: true,
      search: true,
      options: productCatalogOptions,
      value:
        productCatalogsCount === 1
          ? productCatalogs[0].id
          : product.product_catalog_id,
      disabled: productCatalogsCount === 1 || product.id || productGroupPresent
    };

    form.fields.supplier_price.props = {
      ...form.fields.supplier_price.props,
      upperLabel: "product.attributes.supplier_price.label",
      labelPosition: "right"
    };

    form.fields.unit_price.props = {
      ...form.fields.unit_price.props,
      upperLabel: "product.attributes.unit_price.label",
      labelPosition: "right"
    };

    return (
      <>
        <Modal.Content scrolling={!context}>
          <div data-component="CustomProductForm">
            <Form id="product" data-component="productGroup">
              <Form.Group>
                <Form.Field width={8}>
                  <Field component="Input" {...form.fields.name} />
                </Form.Field>
                <FeatureToggleActive featureToggleName="multiple_product_catalogs">
                  <Form.Field width={8}>
                    <Field
                      component="Select"
                      {...form.fields.product_catalog_id}
                    />
                  </Form.Field>
                </FeatureToggleActive>
              </Form.Group>
              <Form.Group>
                <Form.Field width={account.isEnabled("ok20") ? 8 : 16}>
                  <Field component="Input" {...form.fields.sku} />
                </Form.Field>
                {account.isEnabled("ok20") && (
                  <Form.Field width={8}>
                    <Field component="Input" {...form.fields.display_name} />
                  </Form.Field>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Field width={8}>
                  {!newSupplier &&
                    suppliers &&
                    suppliers.length > 0 &&
                    this.renderSupplierDropDown(form)}
                  {(newSupplier || suppliers.length === 0) &&
                    this.renderSupplierInput(form)}
                </Form.Field>
                <Form.Field width={8}>
                  <Field component="Input" {...form.fields.series} />
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <TradesFormField {...form.fields.trades} />
              </Form.Field>
              <Form.Field>
                <Field component="Select" {...form.fields.product_type_ids} />
              </Form.Field>
              <Form.Field>
                <Field component="TextArea" {...form.fields.description} />
              </Form.Field>
              {account.isEnabled("ok20") && (
                <Form.Field>
                  <Field component="Input" {...form.fields.display_hint} />
                </Form.Field>
              )}
              <Form.Field>
                <Field
                  component="TextArea"
                  {...form.fields.technical_description}
                />
              </Form.Field>

              {account.isEnabled("custom_product_code") && (
                <Form.Field>
                  <Field
                    component="Input"
                    {...form.fields.custom_product_code}
                  />
                </Form.Field>
              )}
              {!!form.fields.product_tags.props.options.length && (
                <Form.Field>
                  <Field component="Select" {...form.fields.product_tags} />
                </Form.Field>
              )}

              <Form.Group>
                <Form.Field width={8}>
                  <label htmlFor="supplier_price">
                    <FormattedMessage
                      id={form.fields.supplier_price.props.upperLabel}
                    />
                  </label>
                  <Field
                    component={FloatInput}
                    {...form.fields.supplier_price}
                  />
                </Form.Field>

                <Form.Field width={8}>
                  <label htmlFor="unit_price">
                    <FormattedMessage
                      id={form.fields.unit_price.props.upperLabel}
                    />
                  </label>
                  <Field component={FloatInput} {...form.fields.unit_price} />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field width={8}>
                  <Field component="Input" {...form.fields.supplier_url} />
                </Form.Field>
                <Form.Field width={8}>
                  <Field component="Input" {...form.fields.vr_model_url} />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field width={8}>
                  <Field
                    component="Select"
                    {...form.fields.master_product_id}
                  />
                </Form.Field>
                <Form.Field width={8}>
                  <Field
                    component={ColorInputWithHexField}
                    {...form.fields.color_code}
                  />
                </Form.Field>
              </Form.Group>

              <ProductFieldValues
                value={form.fields.product_field_values.props.value}
                product={product}
                onChange={this.onProductFieldValuesChange}
              />
              <If condition={!!context}>
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <Button
                    id="submit"
                    data-form="product"
                    primary
                    content={i18n["meta.actions.save"]}
                    onClick={form.handleSubmit(this.onSave)}
                    loading={isLoading}
                  />
                </div>
              </If>
            </Form>
          </div>
        </Modal.Content>
        <If condition={!context}>
          <Modal.Actions>
            <Button
              id="cancel"
              basic
              content={i18n["meta.actions.cancel"]}
              loading={isLoading}
              onClick={handleClose}
            />
            <Button
              id="submit"
              data-form="product"
              primary
              content={
                mode === "clone"
                  ? i18n["meta.actions.clone"]
                  : i18n["meta.actions.save"]
              }
              onClick={form.handleSubmit(this.onSave)}
              loading={isLoading}
            />
          </Modal.Actions>
        </If>
      </>
    );
  }
}
CustomProductForm.defaultProps = {
  context: undefined,
  productGroupId: undefined,
  handleClose: () => {},
  handleSave: () => {},
  setShowCloseIcon: () => {},
  mode: undefined
};

CustomProductForm.propTypes = {
  i18n: I18nShape.isRequired,
  context: oneOf(["ProductImporter", "AddCustomProduct"]),
  resources: shape({
    products: instanceOf(ProductResource),
    pricesFactory: func // returns instanceOf(ProductPriceResource)
  }).isRequired,
  model: ProductShape.isRequired,
  account: instanceOf(Account).isRequired,
  productGroupId: number,
  handleUpdate: func.isRequired,
  suppliers: arrayOf(SupplierShape).isRequired,
  handleClose: func,
  handleSave: func,
  productTypes: arrayOf(OptionsShape).isRequired,
  masterProductOptions: arrayOf(OptionsShape).isRequired,
  setShowCloseIcon: func,
  productMetaDataResource: instanceOf(ProductMetaDataResource).isRequired,
  mode: string,
  productCatalogs: arrayOf(ProductCatalogShape).isRequired,
  productCatalogOptions: arrayOf(OptionsShape).isRequired,
  productGroups: arrayOf(ProductGroupShape).isRequired
};

const mapStateToProps = state => ({
  i18n: getI18N(state),
  account: getAccount(state),
  productTypes: getProductTypeOptions(state),
  masterProductOptions: getMasterProductOptions(state),
  productCatalogs: getProductCatalogs(state),
  productCatalogOptions: getProductCatalogOptions(state),
  productGroups: getProductGroups(state)
});

const mapDispatchToProps = dispatch => ({
  productMetaDataResource: new ProductMetaDataResource(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomProductForm);
