import React from "react";
import PropTypes, { instanceOf } from "prop-types";
import { connect } from "react-redux";
import toggleDialogCreator from "builder_portal/helpers/toggleDialogCreator";
import { createSelector, createStructuredSelector } from "reselect";
import { Helmet } from "react-helmet";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import { FormattedMessage } from "react-intl";
import FormattedMessageWithFlag from "shared/components/textFormatting/FormattedMessageWithFlag";
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  List,
  Loader,
  Segment,
  Label
} from "semantic-ui-react";
import { Link } from "react-router";
import { If, Unless } from "shared/components/elements/Conditions";
import {
  getI18N,
  getRoomBookPriceCatalogs,
  getRoomBookCarts,
  getRoomBookCollapsedItems,
  getProductGroups,
  getProducts,
  getProductRepository,
  getProject,
  getTrades,
  getAccount,
  getProjectRooms
} from "builder_portal/selectors";
import { getBaseUnit } from "shared/selectors/unit/unit";
import { getRoomBookSectionMap } from "shared/selectors/configuratorData";
import {
  I18nShape,
  PriceCatalogShape,
  ProductGroupShape,
  ProductShape,
  RoomBookShape,
  ProjectShape,
  UnitShape,
  TradeLabelShape,
  AccountShape
} from "shared/shapes";
import {
  dispatchToggleCollapse,
  dispatchCollapseId,
  getCollapsedById,
  ROOMBOOK_COLLAPSE,
  ROOMBOOK_EXPAND_ALL
} from "shared/reducers/pageContent/roomBookFilter";
import ProjectRoomShape from "shared/shapes/projectRoom.shape";
import { DesignLineGroupsResource } from "builder_portal/actions/designLinesActions";
import copyToClipboard from "copy-to-clipboard";
import ProductGroupSelector from "./ProductGroupSelector";
import InlineInput from "../inlineInput/InlineInput";
import SectionDialog from "./SectionDialog";
import RoomBookEditorDialog from "./RoomBookEditorDialog";
import UnitFeatureIndicators from "./UnitFeatureIndicators";
import RoomBookLineItem from "./RoomBookLineItem";
import DerivedRoomBooks from "./DerivedRoomBooks";
import SectionOrderControl from "./SectionOrderControl";
import CopyToDerivedDialog from "./CopyToDerivedDialog";
import LineItemDialogContainer from "../lineItems/LineItemDialogContainer";
import SubLineItemDialogContainer from "../lineItems/SubLineItemDialogContainer";
import LineItemTimelineDialogContainer from "../lineItems/LineItemTimelineDialogContainer";
import CancellationWizardContainer from "../lineItems/CancellationWizardContainer";
import ProductLightboxContainer from "./ProductLightboxContainer";
import RoomBookController from "../../controllers/roomBook/roomBookController";
import LineItemCreationWizard from "./LineItemCreationWizard";
import SourceInfo from "./SourceInfo";
import ResetButton from "./widgets/ResetButton";
import DeleteButton from "./widgets/DeleteButton";
import ChangePriceCatalogButton from "./widgets/ChangePriceCatalogButton";
import BuyerSelectionBulkControl from "./BuyerSelectionBulkControl";
import CatalogResource from "../../actions/catalogActions";
import { UnitVariableTypesResource } from "../../actions/unitVariablesActions";
import "./roomBook.scss";
import RoomBookUpgradeBundlesResource from "../../resources/roomBooks/RoomBookUpgradeBundlesResource";
import UpgradeBundlesDialog from "./upgradeBundles/UpgradeBundlesDialog";
import { getRoomBookExports } from "../../selectors";
import RoomBookExportShape from "../../../shared/shapes/roomBookExport.shape";
import GeneratorButton from "./widgets/GeneratorButton";
import RoomBookPrototypeResource from "../../actions/roomBookPrototypeActions";
import ConfigurationGroupsResource from "../../actions/configurationGroupsActions";

class RoomBook extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialRender: true
    };
  }

  componentDidMount() {
    const {
      catalogResource,
      unitVariableTypesResource,
      designLineGroupsResource,
      roomBookPrototypeResource,
      configurationGroupsResource
    } = this.props;
    Promise.all([
      roomBookPrototypeResource.fetchAll(),
      catalogResource.fetchAll({ shallow: true }),
      unitVariableTypesResource.fetchAll(),
      designLineGroupsResource.fetchAll(),
      configurationGroupsResource.fetchAll()
    ]);
  }

  handleToggleCollapse = itemId => {
    const { roomBook, collapseId, toggleCollapse } = this.props;
    const { initialRender } = this.state;
    // section are collapsed on inital render, but actual value is still present - collapse them before expanding the first clicked section
    if (initialRender)
      roomBook.sections?.forEach(({ id }) => collapseId(`item-${id}`));
    this.setState({ initialRender: false }, () => toggleCollapse(itemId));
  };

  handleRoomBookChange = values => {
    const { ctrl } = this.props;

    return ctrl.updateRoomBook(values);
  };

  expandAll = () => {
    const { expandAll } = this.props;
    this.setState({ initialRender: false }, () => {
      expandAll();
    });
  };

  handleClipboardCopy = (e, val) => {
    e.preventDefault();
    copyToClipboard(val);
  };

  isLoading() {
    const { roomBook, products } = this.props;

    return !(roomBook && products.length);
  }

  renderEmpty() {
    const { i18n } = this.props;
    const pageName = [i18n["roomBook.title.one"], i18n["meta.app.name"]].join(
      " - "
    );

    return (
      <Grid stackable data-component="roomBookItem">
        <Helmet title={pageName} />
        <Grid.Column width="16">
          <Loader active />
        </Grid.Column>
      </Grid>
    );
  }

  renderItem() {
    const { i18n, ctrl, projectId, roomBook, unit, account } = this.props;
    const pageName = [
      roomBook.template
        ? roomBook.name
        : `${i18n["roomBook.title.one"]} ${unit?.display_name}`,
      i18n["meta.app.name"]
    ].join(" - ");
    return (
      <div
        data-component="roomBookItem"
        className={(roomBook.template && "isTemplate") || null}
      >
        {roomBook && roomBook.template && (
          <>
            <Helmet title={pageName} />
            <Header sub>
              <Link onlyActiveOnIndex to={`/projects/${projectId}/room_books`}>
                <FormattedMessage
                  id="roomBook.templateTitle.one"
                  defaultText="roomBook.templateTitle.one"
                />
              </Link>
            </Header>
            <Grid stackable verticalAlign="middle">
              <Grid.Column width="8" className="unitAndBuyer">
                <InlineInput
                  model={roomBook}
                  fieldName="name"
                  labelPosition="left"
                  transparent
                  fluid
                  onChange={this.handleRoomBookChange}
                />
              </Grid.Column>
              <Grid.Column width="8" textAlign="right" />
            </Grid>
          </>
        )}
        {roomBook && roomBook.template && (
          <Segment>
            {this.renderRoomBook(roomBook)}{" "}
            {this.renderRoomBookBottom(roomBook)}
          </Segment>
        )}
        {roomBook && !roomBook.template && (
          <>
            {this.renderRoomBook(roomBook)}{" "}
            {this.renderRoomBookBottom(roomBook)}
          </>
        )}
        <LineItemDialogContainer
          module="roomBook"
          id={roomBook.id}
          ctrl={ctrl}
          template={roomBook.template}
        />
        <SubLineItemDialogContainer
          module="roomBook"
          id={roomBook.id}
          ctrl={ctrl}
          template={roomBook.template}
          projectId={projectId}
        />
        <LineItemTimelineDialogContainer
          module="roomBook"
          id={roomBook.id}
          account={account}
        />
        <ProductLightboxContainer
          module="roomBook"
          id={roomBook.id}
          ctrl={ctrl}
        />
        <CancellationWizardContainer
          module="roomBook"
          id={roomBook.id}
          ctrl={ctrl}
        />
        <LineItemCreationWizard
          module="roomBook"
          id={roomBook.id}
          ctrl={ctrl}
        />
      </div>
    );
  }

  renderRoomBook(roomBook) {
    const {
      i18n,
      productsRepository,
      priceCatalogs,
      ctrl,
      collapseAll,
      projectId,
      roomBookExports
    } = this.props;
    return (
      <div data-component="line_items">
        <Grid verticalAlign="middle" stackable>
          <Grid.Column width="12" data-component="roomBookControls">
            <Button.Group basic compact>
              <Dropdown
                className="button icon"
                floating
                options={[
                  {
                    key: "collapseAllConfirmedSection",
                    icon: (
                      <Icon.Group>
                        <Icon name="folder outline" />
                        <Icon corner="bottom left" name="check" />
                      </Icon.Group>
                    ),
                    text: i18n["roomBook.actions.collapseAllConfirmedSection"],
                    value: "collapseAllConfirmedSection",
                    onClick: () => {
                      const payload = {};
                      // good luck using a recursive function, was more mindfck than this manual iteration
                      roomBook.sections.forEach(section => {
                        payload[`item-${section.id}`] = true;
                        section.sections.forEach(subSection => {
                          payload[`item-${subSection.id}`] = true;
                          subSection.line_items.forEach(lineItem => {
                            payload[`line-item-${lineItem.id}`] = true;
                            lineItem.sub_line_items.forEach(subLineItem => {
                              const isCustomProduct =
                                subLineItem.product_id === null; // customProducts are always confirmed
                              payload[`sub-line-item-${subLineItem.id}`] =
                                isCustomProduct ||
                                !!subLineItem.selection_is_confirmed;

                              payload[`line-item-${lineItem.id}`] =
                                payload[`line-item-${lineItem.id}`] &&
                                payload[`sub-line-item-${subLineItem.id}`];
                            });
                            payload[`item-${subSection.id}`] =
                              payload[`item-${subSection.id}`] &&
                              payload[`line-item-${lineItem.id}`];
                          });
                          payload[`item-${section.id}`] =
                            payload[`item-${section.id}`] &&
                            payload[`item-${subSection.id}`];
                        });
                      });

                      collapseAll(payload);
                    }
                  }
                ]}
                trigger={<></>}
              />
              <Button
                icon="folder open outline"
                content={i18n["roomBook.actions.expandSection"]}
                onClick={() => this.expandAll()}
              />
              <Button
                icon="folder outline"
                content={i18n["roomBook.actions.collapseSection"]}
                onClick={() => {
                  const payload = {};
                  roomBook.sections.forEach(section => {
                    payload[`item-${section.id}`] = true;
                    section.sections.forEach(subSection => {
                      payload[`item-${subSection.id}`] = true;
                      subSection.line_items.forEach(lineItem => {
                        payload[`line-item-${lineItem.id}`] = true;
                        lineItem.sub_line_items.forEach(subLineItem => {
                          payload[`sub-line-item-${subLineItem.id}`] = true;
                        });
                      });
                    });
                  });

                  collapseAll(payload);
                }}
              />
            </Button.Group>

            <Button.Group basic compact>
              <RoomBookEditorDialog
                i18n={i18n}
                roomBook={roomBook}
                ctrl={ctrl}
                productsRepository={productsRepository}
                button={
                  <Button
                    icon="calculator"
                    content={i18n["meta.actions.edit"]}
                  />
                }
              />
            </Button.Group>

            <Button.Group basic compact>
              <SourceInfo roomBook={roomBook} />
            </Button.Group>

            <If condition={roomBook.template}>
              <Button.Group basic compact>
                <DerivedRoomBooks
                  roomBookId={roomBook.id}
                  projectId={projectId}
                />
              </Button.Group>
            </If>
            <Button.Group basic compact>
              <ChangePriceCatalogButton
                roomBook={roomBook}
                priceCatalogs={priceCatalogs}
                ctrl={ctrl}
              />
            </Button.Group>
            <If condition={roomBook.template}>
              <Button.Group basic compact>
                <GeneratorButton
                  roomBook={roomBook}
                  ctrl={ctrl}
                  buttonProps={{
                    id: "change-price-catalog",
                    icon: "magic",
                    content: "Generiern"
                  }}
                />
              </Button.Group>
            </If>

            <RoomBookUpgradeBundlesResource roomBookId={roomBook?.id}>
              <UpgradeBundlesDialog
                trigger={
                  <Button.Group basic compact>
                    <Button icon="box" content="Upgrades" />
                  </Button.Group>
                }
              />
            </RoomBookUpgradeBundlesResource>

            <Button.Group basic compact>
              <Button
                icon="clipboard outline"
                content={i18n["roomBook.lineItems.actions.copyIdShort"]}
                onClick={e => this.handleClipboardCopy(e, roomBook.id)}
              />
            </Button.Group>
          </Grid.Column>

          <Grid.Column width="4" textAlign="right">
            {!roomBook.template && roomBookExports.length > 0 && (
              <div>
                <Icon name="file excel outline" />
                <Dropdown
                  text={i18n["roomBook.actions.export.label"]}
                  inline
                  pointing="bottom"
                >
                  <Dropdown.Menu className="left">
                    {roomBookExports.map((exp, idx) => {
                      return (
                        <Dropdown.Item key={idx}>
                          {exp.label} (
                          {exp.options.map((o, idx) => {
                            return (
                              <span key={o.label}>
                                {idx !== 0 ? "|" : ""}
                                <a
                                  href={o.url}
                                  target={`${roomBook.id}_{exp.label}`}
                                  style={{
                                    color: "#00F",
                                    textDecoration: "underline"
                                  }}
                                >
                                  {o.label}
                                </a>
                              </span>
                            );
                          })}
                          )
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </Grid.Column>
        </Grid>
        <BuyerSelectionBulkControl ctrl={ctrl} />
        {/* sections */}
        {roomBook.sections &&
          roomBook.sections.length > 0 &&
          this.renderPrimarySections(roomBook.sections)}
        {roomBook.sections && roomBook.sections.length === 0 && (
          <div className="margin top spacious">
            <FormattedMessage
              id="roomBook.sections.primary.notFound"
              defaultText="roomBook.sections.primary.notFound"
            />
          </div>
        )}

        {/* add primary section button */}
        <Segment data-component="pseudoItem">
          <SectionDialog
            roomBookId={roomBook.id}
            model={{}}
            type="primary"
            i18n={i18n}
            ctrl={ctrl}
            isTemplate={roomBook.template}
            button={
              <Button
                id="section-new"
                basic
                content={i18n["roomBook.actions.addPrimarySection"]}
                icon="plus"
                className="addPrimarySection"
                style={{ width: "100%" }}
              />
            }
          />
        </Segment>

        {/* total */}
        {!roomBook.template &&
          roomBook.sections &&
          roomBook.sections.length > 0 &&
          this.renderTotal(roomBook.total, "roomBookTotal")}
      </div>
    );
  }

  renderRoomBookBottom(roomBook) {
    return (
      <div>
        <Grid
          data-component="room-book-bottom"
          stackable
          verticalAlign="middle"
        >
          <Grid.Column width="4" />
          <Grid.Column width="12" textAlign="right">
            <>
              <If condition={!!this.props.unit && roomBook.sources.length > 0}>
                <ResetButton ctrl={this.props.ctrl} />
              </If>
              <If condition={roomBook.template}>
                <DeleteButton ctrl={this.props.ctrl} mode="archive" />
              </If>
            </>
            <Unless condition={roomBook.template}>
              <DeleteButton ctrl={this.props.ctrl} mode="archive" />
            </Unless>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  renderPrimarySections(sections) {
    const {
      roomBook,
      i18n,
      ctrl,
      collapsedItems,
      account,
      roomBookSectionMap
    } = this.props;
    const { initialRender } = this.state;
    const hashId = parseInt(window.location.hash.replace(/[a-z-#]*/i, ""), 10);

    return sections.map(sectionItem => {
      // hide sections on initial render, whatever is actual value (except for hash links)
      const collapsed = initialRender
        ? !hashId ||
          (hashId &&
            roomBookSectionMap[sectionItem.display_number].indexOf(hashId) ===
              -1)
        : getCollapsedById(
            { roomBookFilter: { collapsedItems } },
            `item-${sectionItem.id}`
          );

      return (
        <Segment
          data-model="primarySection"
          data-id={sectionItem.id}
          id={`item-${sectionItem.id}`}
          data-component="roomBookPrimarySectionItem"
          role="listitem"
          key={`primary-section-item-${sectionItem.id}`}
        >
          <div className="sectionHeader">
            <Grid stackable verticalAlign="middle">
              <Grid.Column width="13" verticalAlign="middle">
                <Button
                  as="div"
                  basic
                  size="large"
                  className="toggleSection"
                  onClick={() =>
                    this.handleToggleCollapse(`item-${sectionItem.id}`)
                  }
                  icon={
                    <Icon
                      name={`folder outline${collapsed ? "" : " open"}`}
                      size="large"
                      link
                      title={account.isSystemAdmin() ? sectionItem.id : ""}
                    />
                  }
                />
                <SectionDialog
                  roomBookId={roomBook.id}
                  model={sectionItem}
                  type="primary"
                  i18n={i18n}
                  ctrl={ctrl}
                  isTemplate={roomBook.template}
                  button={
                    <div className="titleButton" style={{ marginTop: "3px" }}>
                      <Icon color="grey" name="setting" size="large" link />
                    </div>
                  }
                />
                <Header
                  className="toggleSection left floated element"
                  onClick={() =>
                    this.handleToggleCollapse(`item-${sectionItem.id}`)
                  }
                >
                  <span data-attr="display_number">{sectionItem.position}</span>
                  &nbsp;<span data-attr="title">{sectionItem.title}</span>
                </Header>
                <UnitFeatureIndicators
                  item={sectionItem}
                  isTemplate={roomBook.template}
                  basic
                />
              </Grid.Column>
              <Grid.Column width="3" textAlign="right">
                <CopyToDerivedDialog
                  roomBookId={roomBook.id}
                  item={sectionItem}
                  itemType="main_section"
                  trigger={
                    <Button size="tiny" data-component="copy-to-derived-button">
                      Übernehmen
                    </Button>
                  }
                />
                <SectionOrderControl
                  i18n={i18n}
                  type="primarySection"
                  ctrl={ctrl}
                  element={sectionItem}
                  itemsInGroup={sections.length}
                  roomBook={roomBook}
                />
              </Grid.Column>
            </Grid>
          </div>
          {collapsed ? null : (
            <List
              verticalAlign="middle"
              data-component="roomBookSecondarySectionList"
            >
              {this.renderSecondarySections(sectionItem)}
              <Segment
                data-component="pseudoItem"
                className="margin bottom spacious"
              >
                <SectionDialog
                  roomBookId={roomBook.id}
                  parentId={sectionItem.id}
                  model={{}}
                  type="secondary"
                  i18n={i18n}
                  ctrl={ctrl}
                  isTemplate={roomBook.template}
                  button={
                    <Button
                      id="subsection-new"
                      basic
                      fluid
                      content={i18n["roomBook.actions.addSecondarySection"]}
                      icon="plus"
                      className="addSecondarySection"
                    />
                  }
                />
              </Segment>
              {this.renderSectionFooter({
                sectionItem,
                languageKey: "subtotal",
                iconModifier: "double up",
                isTemplate: roomBook.template
              })}
            </List>
          )}
        </Segment>
      );
    });
  }

  renderSecondarySections(parent) {
    const {
      roomBook,
      productGroups,
      i18n,
      toggleDialog,
      trades,
      ctrl,
      collapsedItems,
      account,
      projectRooms
    } = this.props;
    return parent.sections.map(sectionItem => {
      const collapsed = getCollapsedById(
        { roomBookFilter: { collapsedItems } },
        `item-${sectionItem.id}`
      );
      const displayNumber = `${parent.position}.${sectionItem.position}`;
      return (
        <List.Item
          data-model="secondarySection"
          data-id={sectionItem.id}
          id={`item-${sectionItem.id}`}
          data-component="roomBookSecondarySectionItem"
          key={`secondary-section-item-${sectionItem.id}`}
        >
          <List.Content>
            <Grid stackable verticalAlign="middle">
              <Grid.Column width="13">
                <Button
                  as="div"
                  basic
                  size="large"
                  className="toggleSection"
                  onClick={() =>
                    this.handleToggleCollapse(`item-${sectionItem.id}`)
                  }
                  icon={
                    <Icon
                      name={`folder outline${collapsed ? "" : " open"}`}
                      size="large"
                      link
                      title={account.isSystemAdmin() ? sectionItem.id : ""}
                    />
                  }
                />
                <SectionDialog
                  parentId={parent.id}
                  roomBookId={roomBook.id}
                  model={sectionItem}
                  type="secondary"
                  i18n={i18n}
                  ctrl={ctrl}
                  isTemplate={roomBook.template}
                  button={
                    <div className="titleButton" style={{ marginTop: "3px" }}>
                      <Icon color="grey" name="setting" size="large" link />
                    </div>
                  }
                />
                <Header
                  className="toggleSection left floated element"
                  onClick={() =>
                    this.handleToggleCollapse(`item-${sectionItem.id}`)
                  }
                >
                  <span data-attr="display_number">{displayNumber}</span>{" "}
                  <span data-attr="title">{sectionItem.title}</span>
                </Header>
                {sectionItem.project_room_ids?.map(projectRoomId => (
                  <Label key={projectRoomId}>
                    <Icon name="tv" />
                    {projectRooms.find(pr => pr.id === projectRoomId)?.title}
                  </Label>
                ))}
                <UnitFeatureIndicators
                  item={sectionItem}
                  isTemplate={roomBook.template}
                />
              </Grid.Column>
              <Grid.Column width="3" textAlign="right">
                <CopyToDerivedDialog
                  roomBookId={roomBook.id}
                  item={sectionItem}
                  itemType="sub_section"
                  trigger={
                    <Button size="tiny" data-component="copy-to-derived-button">
                      Übernehmen
                    </Button>
                  }
                />
                <SectionOrderControl
                  i18n={i18n}
                  type="secondarySection"
                  ctrl={ctrl}
                  element={sectionItem}
                  itemsInGroup={parent.sections.length}
                  roomBook={roomBook}
                />
              </Grid.Column>
              {!collapsed && (
                <Grid.Column width="16">
                  <List
                    verticalAlign="middle"
                    data-component="roomBookLineItemList"
                  >
                    {this.renderLineItems(displayNumber, sectionItem)}

                    <Segment
                      data-component="pseudoItem"
                      className="margin bottom spacious"
                    >
                      <ProductGroupSelector
                        i18n={i18n}
                        toggleDialog={toggleDialog}
                        label={i18n["roomBook.actions.addLineItem"]}
                        productGroups={productGroups}
                        trades={trades}
                        siblings={sectionItem.line_items}
                        roomBook={roomBook}
                        parentType="section"
                        parentId={sectionItem.id}
                        ctrl={ctrl}
                      />
                    </Segment>
                    <List.Item data-component="roomBookLineItem">
                      <List.Content>
                        {this.renderSectionFooter({
                          sectionItem,
                          languageKey: "subsubtotal",
                          isTemplate: roomBook.template
                        })}
                      </List.Content>
                    </List.Item>
                  </List>
                </Grid.Column>
              )}
            </Grid>
          </List.Content>
        </List.Item>
      );
    });
  }

  renderSectionFooter({ sectionItem, languageKey, iconModifier, isTemplate }) {
    const { i18n } = this.props;
    return (
      <Grid
        width="16"
        className={(languageKey === "subtotal" && "sectionFooter") || null}
      >
        <Grid.Column width="2" verticalAlign="middle">
          <Header
            as="h4"
            onClick={() => this.handleToggleCollapse(`item-${sectionItem.id}`)}
          >
            <Icon
              name={`angle ${iconModifier || "up"}`}
              color="grey"
              className="sectionToggle"
              size="large"
            />
            <Header.Content className="sectionToggle">
              <Header.Subheader
                content={i18n["roomBook.actions.collapseSection"]}
              />
            </Header.Content>
          </Header>
        </Grid.Column>
        <If condition={!isTemplate}>
          <Grid.Column width="14">
            {this.renderTotal(
              sectionItem.total,
              languageKey,
              sectionItem.title
            )}
          </Grid.Column>
        </If>
      </Grid>
    );
  }

  renderLineItems(parentDisplayNumber, section) {
    const {
      i18n,
      featureToggles,
      productsRepository,
      roomBook,
      carts,
      project,
      unit,
      ctrl,
      toggleDialog,
      trades,
      context,
      projectId
    } = this.props;
    return section.line_items.map(lineItem => {
      // productDictionary contains products that are available in the current group
      return (
        <RoomBookLineItem
          projectId={projectId}
          parentDisplayNumber={parentDisplayNumber}
          featureToggles={featureToggles}
          context={context}
          section={section}
          key={`line-item-${lineItem.id}`}
          i18n={i18n}
          carts={carts}
          trades={trades}
          roomBook={roomBook}
          project={project}
          isTemplate={(roomBook && roomBook.template) || (unit && unit.sample)}
          productsRepository={productsRepository}
          model={lineItem}
          ctrl={ctrl}
          toggleDialog={toggleDialog}
        />
      );
    });
  }

  renderTotal(total, languageKey, label) {
    return (
      <List.Item
        className={`nonInteractive ${languageKey}`}
        key={`line-item-${languageKey}`}
      >
        <List.Content>
          <Grid stackable verticalAlign="middle">
            <Grid.Column width="14" textAlign="right">
              <strong>
                <FormattedMessageWithFlag
                  featureToggleName="show_price_net_label"
                  id={`roomBook.${languageKey}.net`}
                  alternativeId={`roomBook.${languageKey}.gross`}
                />
                {label && ` ${label}`}:
              </strong>
            </Grid.Column>
            <Grid.Column width="2" textAlign="right">
              <strong data-attr={languageKey}>
                <FormatCurrency amount={total} />
              </strong>
            </Grid.Column>
          </Grid>
        </List.Content>
      </List.Item>
    );
  }

  render() {
    if (this.isLoading()) {
      return this.renderEmpty();
    }
    return this.renderItem();
  }
}

RoomBook.defaultProps = {
  carts: [],
  priceCatalogs: [],
  roomBook: null,
  trades: [],
  products: [],
  productGroups: [],
  featureToggles: {},
  project: null,
  unit: null
};

RoomBook.propTypes = {
  account: AccountShape.isRequired,
  i18n: I18nShape.isRequired,
  featureToggles: PropTypes.object,
  ctrl: PropTypes.instanceOf(RoomBookController).isRequired,
  carts: PropTypes.array,
  projectId: PropTypes.string.isRequired,
  productsRepository: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  trades: PropTypes.arrayOf(TradeLabelShape),
  toggleDialog: PropTypes.func.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
  collapseId: PropTypes.func.isRequired,
  expandAll: PropTypes.func.isRequired,
  collapseAll: PropTypes.func.isRequired,
  collapsedItems: PropTypes.object.isRequired,
  priceCatalogs: PropTypes.arrayOf(PriceCatalogShape),
  roomBook: RoomBookShape,
  productGroups: PropTypes.arrayOf(ProductGroupShape),
  products: PropTypes.arrayOf(ProductShape),
  project: ProjectShape,
  unit: UnitShape,
  catalogResource: instanceOf(CatalogResource).isRequired,
  projectRooms: PropTypes.arrayOf(ProjectRoomShape).isRequired,
  roomBookExports: PropTypes.arrayOf(RoomBookExportShape).isRequired,
  unitVariableTypesResource: PropTypes.instanceOf(UnitVariableTypesResource)
    .isRequired,
  designLineGroupsResource: PropTypes.instanceOf(DesignLineGroupsResource)
    .isRequired,
  roomBookPrototypeResource: PropTypes.instanceOf(RoomBookPrototypeResource)
    .isRequired,
  configurationGroupsResource: PropTypes.instanceOf(ConfigurationGroupsResource)
    .isRequired,
  id: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  roomBookSectionMap: PropTypes.object.isRequired
};

const mapStateToProps = createStructuredSelector({
  account: getAccount,
  i18n: getI18N,
  carts: getRoomBookCarts,
  productsRepository: getProductRepository,
  productGroups: getProductGroups,
  products: getProducts,
  unit: getBaseUnit,
  collapsedItems: getRoomBookCollapsedItems,
  priceCatalogs: getRoomBookPriceCatalogs,
  project: getProject,
  trades: getTrades,
  projectRooms: getProjectRooms,
  roomBookExports: getRoomBookExports,
  roomBookSectionMap: getRoomBookSectionMap
});

const makeGetToggleDialog = () => {
  return createSelector(
    [dispatch => dispatch, (_, props) => props.id],
    (dispatch, roomBookId) => {
      return toggleDialogCreator(dispatch, roomBookId, "roomBook");
    }
  );
};

const makeMapDispatchToProps = () => {
  const getToggleDialog = makeGetToggleDialog();
  return (dispatch, props) => {
    const { id, projectId, mode, roomBook } = props;
    const expandAll = () => {
      return dispatch({
        type: ROOMBOOK_EXPAND_ALL
      });
    };
    const collapseAll = payload => {
      return dispatch({
        type: ROOMBOOK_COLLAPSE,
        payload
      });
    };

    return {
      ctrl: new RoomBookController(projectId, id, mode, dispatch),
      toggleCollapse: itemId => {
        dispatchToggleCollapse(dispatch, itemId);
      },
      collapseId: itemId => {
        dispatchCollapseId(dispatch, itemId);
      },
      toggleDialog: getToggleDialog(dispatch, props),
      collapseAll,
      expandAll,
      catalogResource: new CatalogResource(dispatch),
      roomBookPrototypeResource: new RoomBookPrototypeResource(dispatch),
      unitVariableTypesResource: new UnitVariableTypesResource(
        dispatch,
        projectId
      ),
      designLineGroupsResource: new DesignLineGroupsResource(
        dispatch,
        roomBook.project_catalog_id
      ),
      configurationGroupsResource: new ConfigurationGroupsResource(
        dispatch,
        projectId
      )
    };
  };
};

export default connect(mapStateToProps, makeMapDispatchToProps)(RoomBook);
