import { string, number, shape, objectOf, bool } from "prop-types";

export const ProjectShape = shape({
  developer_contact_person: string,
  developer_email: string,
  developer_name: string,
  developer_phone: string,
  id: number,
  label: string,
  name: string,
  process_type: string,
  slug: string,
  status: string,
  thumb_url: string,
  account_manager_id: number,
  features: objectOf(bool)
});

export default ProjectShape;
