import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Header, Label, Segment, Icon, Accordion } from "semantic-ui-react";
import "./buyerDeveloperSegment.scss";

class BuyerDeveloperSegment extends React.Component {
  static propTypes = {
    buyers: PropTypes.array,
    project: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  getPanels() {
    const { buyers, project } = this.props;

    const children = [];
    if (buyers.length) {
      buyers.forEach(buyer => {
        children.push({
          key: `buyer-panel-${buyer.id}`,
          title: {
            key: `buyer-title-${buyer.id}`,
            content: (
              <Header size="small" color="teal">
                <Icon name="child" />
                <span>{buyer.label}</span>
              </Header>
            )
          },
          content: {
            key: `buyer-content-${buyer.id}`,
            content: (
              <div className="contact">
                {buyer.email && (
                  <p className="email">
                    <Icon name="mail" /> {buyer.email}
                  </p>
                )}
                {buyer.phone && (
                  <p className="phone">
                    <Icon name="phone" /> {buyer.phone}
                  </p>
                )}
              </div>
            )
          }
        });
      });
    } else {
      children.push({
        key: "buyer-panel-none",
        title: {
          key: "buyer-title",
          content: (
            <Header size="small">
              <Icon name="attention" />
              <FormattedMessage
                id="buyer.message.noneAssigned"
                defaultText="buyer.message.noneAssigned"
              />
            </Header>
          )
        }
      });
    }

    if (project && project.developer_name) {
      children.push({
        key: "developer-panel",
        title: {
          key: "developer-title",
          content: (
            <Header size="small" color="pink">
              <Icon name="building outline" />
              <span>{project && project.developer_name}</span>
            </Header>
          )
        },
        content: {
          key: "developer-content",
          content: (
            <div className="contact">
              {project && project.developer_contact_person && (
                <p className="email">
                  <Icon name="user" /> {project.developer_contact_person}
                </p>
              )}
              {project && project.developer_email && (
                <p className="email">
                  <Icon name="mail" /> {project.developer_email}
                </p>
              )}
              {project && project.developer_phone && (
                <p className="phone">
                  <Icon name="phone" /> {project.developer_phone}
                </p>
              )}
            </div>
          )
        }
      });
    }
    return children;
  }

  render() {
    return (
      <Segment data-component="buyerDeveloperSegment">
        <Label attached="top" basic>
          <FormattedMessage
            id="buyer.title.one"
            defaultText="buyer.title.one"
          />{" "}
          &{" "}
          <FormattedMessage
            id="project.attributes.developerName.label"
            defaultText="project.attributes.developerName.label"
          />
        </Label>
        <Accordion panels={this.getPanels()} />
      </Segment>
    );
  }
}

export default BuyerDeveloperSegment;
