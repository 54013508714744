import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Container,
  Modal,
  Header,
  Input,
  Loader,
  Icon,
  Grid,
  Card,
  TextArea
} from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import { func, string, object } from "prop-types";
import { set, merge } from "lodash";
import silentHandleApiRequestErrors from "shared/helpers/silentHandleApiRequestErrors";

const flatten = data => {
  const result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      const l = cur.length;
      for (let i = 0; i < l; i += 1) recurse(cur[i], `${prop}[${i}]`);
      if (l === 0) result[prop] = [];
    } else {
      let isEmpty = true;
      // eslint-disable-next-line
      for (const p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? `${prop}.${p}` : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, "");
  return result;
};

const contains = (key, value, filter) => {
  const fwords = filter.trim().split(" ");
  const containsInKey = fwords.every(w => key.toLowerCase().indexOf(w) > -1);
  const containsInValue = fwords.every(
    w => value.toLowerCase().indexOf(w) > -1
  );

  return containsInKey || containsInValue;
};

const Translations = ({ json, handleApply, fieldName }) => {
  const [translations, setTranslations] = useState({});
  const [selected, setSelected] = useState(flatten(json));
  const [isLoading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const originalTranslations = useRef();

  useEffect(() => {
    const flattenSelected = flatten(json);
    setSelected(flattenSelected);
    setTranslations({ ...translations, ...flattenSelected });
  }, [json]);

  const setTranslationKey = vals => {
    let currentJson = { ...json };
    vals.forEach(val => {
      // if the number (n) is the last part of keyName, lodash set doesn't work ok - takes it as n-th element
      const temp = {};
      const keys = val.key.split(".");
      const lastKey = keys.slice(-1);
      keys.pop();
      set(temp, keys, { [lastKey]: val.translation });
      currentJson = merge(currentJson, temp);
    });

    handleApply(fieldName, currentJson);
  };

  const removeTranslationKey = key => {
    // remove all the languages for this key
    // remove from formik values
    const flattenJson = flatten(json);
    languages.forEach(language => {
      delete flattenJson[`${language}.${key}`];
    });
    let tempJson = {};
    // restore flatten to object
    Object.entries(flattenJson).forEach(([keyName, value]) => {
      const temp = {};
      const keys = keyName.split(".");
      const lastKey = keys.slice(-1);
      keys.pop();
      set(temp, keys, { [lastKey]: value });
      tempJson = merge(tempJson, temp);
    });
    handleApply(fieldName, tempJson);
    // reset translations state
    const tempTrfanslations = { ...translations };
    languages.forEach(language => {
      tempTrfanslations[`${language}.${key}`] =
        originalTranslations.current?.[`${language}.${key}`];
    });
    setTranslations(tempTrfanslations);
  };

  useEffect(() => {
    setLoading(true);
    fetch(`https://configurator.baudigital.com/api/v1/translations`)
      .then(res => res.json())
      .then(data => {
        setLanguages(Object.keys(data.translations));
        const flattenTranslations = flatten(data.translations);
        originalTranslations.current = flattenTranslations;
        setTranslations({ ...flattenTranslations, ...selected });

        setLoading(false);
      })
      .catch(silentHandleApiRequestErrors);
  }, []);

  if (isLoading) return <Loader active style={{ marginTop: "110px" }} />;

  return (
    <Container>
      <If condition={!!Object.keys(translations)}>
        <AddTranslation
          flattenTranslations={translations}
          addTranslationKey={setTranslationKey}
        />
      </If>
      <Card.Group style={{ margin: "0px", width: "100%" }}>
        {Object.keys(selected).map(item => (
          <Card fluid style={{ padding: "7px", margin: "2px 0" }} key={item}>
            <Grid>
              <Grid.Column width={14}>
                <Header as="h5">{selected[item]}</Header>
                {item}
              </Grid.Column>
              <Grid.Column width={2} verticalAlign="middle" textAlign="right">
                <EditTranslation
                  keyName={item}
                  flattenTranslations={translations}
                  editTranslationKey={setTranslationKey}
                  removeTranslationKey={removeTranslationKey}
                />
              </Grid.Column>
            </Grid>
          </Card>
        ))}
      </Card.Group>
    </Container>
  );
};

Translations.propTypes = {
  handleApply: func.isRequired,
  fieldName: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  json: object.isRequired
};

export default Translations;

// Add tranlsation modal
const AddTranslation = ({ flattenTranslations, addTranslationKey }) => {
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const [filter, setFilter] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      closeIcon
      trigger={
        <Button
          basic
          fluid
          onClick={e => e.preventDefault()}
          style={{ margin: "8px 0" }}
        >
          <FormattedMessage id="configuratorDesign.dialog.translationSreach.label" />
        </Button>
      }
    >
      <Modal.Header>
        <FormattedMessage id="configuratorDesign.dialog.translationSreach.label" />
      </Modal.Header>
      <Modal.Content scrolling>
        <div style={{ minHeight: "500px" }}>
          <Input
            fluid
            icon="search"
            placeholder={intl.formatMessage({
              id: "configuratorDesign.dialog.translationSreach.label"
            })}
            value={filter}
            onChange={(_, { value }) => setFilter(value.toLowerCase())}
          />
          {Object.keys(flattenTranslations)
            .filter(key => contains(key, flattenTranslations[key], filter))
            .slice(0, filter.length > 5 ? 100 : 20)
            .sort(
              (a, b) =>
                flattenTranslations[a].length - flattenTranslations[b].length
            )
            .map(key => (
              <Button
                key={key}
                fluid
                basic
                style={{ margin: "5px 0", textAlign: "left" }}
                onClick={() => {
                  addTranslationKey([
                    { key, translation: flattenTranslations[key] }
                  ]);
                  handleClose();
                }}
              >
                <Header as="h4">{flattenTranslations[key]}</Header>
                <div>{key}</div>
              </Button>
            ))}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button basic id="cancel" onClick={handleClose}>
          <FormattedMessage id="meta.actions.cancel" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

AddTranslation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  flattenTranslations: object.isRequired,
  addTranslationKey: func.isRequired
};

// Edit tranlsation modal
const EditTranslation = ({
  keyName,
  flattenTranslations,
  editTranslationKey,
  removeTranslationKey
}) => {
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({});

  const handleOpen = () => {
    const vals = {};
    Object.keys(flattenTranslations)
      .filter(key => key.slice(3) === keyName.slice(3))
      .forEach(key => {
        const lang = key.slice(0, 2);
        vals[lang] = flattenTranslations[key];
      });
    setValues(vals);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleChange = (e, lang) => {
    const { value } = e.target;
    const temp = { ...values };
    temp[lang] = value;
    setValues(temp);
  };

  const handleApply = () => {
    const translations = [];
    Object.entries(values).forEach(([lang, translation]) => {
      const keyForLanguage = `${lang}.${keyName.slice(3)}`;
      if (flattenTranslations[keyForLanguage] !== translation)
        translations.push({ key: keyForLanguage, translation });
    });
    if (translations.length) editTranslationKey(translations);
    handleClose();
  };

  const handleRemove = () => {
    removeTranslationKey(keyName.slice(3));
  };

  return (
    <Modal
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      trigger={
        <Icon name="setting" color="gray" style={{ cursor: "pointer" }} />
      }
      closeIcon
    >
      <Modal.Header>
        Text bearbeiten
        <div style={{ fontSize: "0.9rem", fontWeight: 200 }}>
          {keyName.slice(3)}
        </div>
      </Modal.Header>
      <Modal.Content style={{ minHeight: "400px" }}>
        {Object.entries(values).map(([lang, translation]) => (
          <>
            <div style={{ fontWeight: 700, margin: "7px 0" }}>
              <FormattedMessage id={`meta.languages.${lang}`} />
            </div>
            <TextArea
              key={lang}
              value={translation}
              onChange={e => handleChange(e, lang)}
              style={{ width: "100%" }}
            />
          </>
        ))}
      </Modal.Content>
      <Modal.Actions>
        <Grid>
          <Grid.Column width={8} textAlign="left">
            <Button basic color="red" id="remove" onClick={handleRemove}>
              <FormattedMessage id="meta.actions.remove" />
            </Button>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <Button basic id="cancel" onClick={handleClose}>
              <FormattedMessage id="meta.actions.cancel" />
            </Button>
            <Button primary id="apply" onClick={handleApply}>
              <FormattedMessage id="meta.actions.apply" />
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
};

EditTranslation.propTypes = {
  keyName: string.isRequired,
  editTranslationKey: func.isRequired,
  removeTranslationKey: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  flattenTranslations: object.isRequired
};
