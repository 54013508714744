import { get } from "lodash";
import { createSelector } from "reselect";
import { getProject, getUserToken, getAccount } from "shared/selectors";
import { getPageContent } from "shared/selectors/base";

export const DEFAULT_TAX_RATE = 19.0;

export const getAnalytics = createSelector([getPageContent], pageContent => {
  return get(pageContent, "analytics");
});

export const getReportDownloads = createSelector(
  [getUserToken, getProject, getAccount],
  (token, project, account) =>
    project
      ? [
          {
            id: "sub_line_items_report",
            title: "unit.reportDownloads.sub_line_items.title",
            description: "unit.reportDownloads.sub_line_items.description",
            url: `/api/v1/projects/${project.slug}/reports/sub_line_items.xlsx?_bearer=${token}`
          },
          {
            id: "ordered_products",
            title: "unit.reportDownloads.ordered_products.title",
            description: "unit.reportDownloads.ordered_products.description",
            url: `/api/v1/projects/${project.slug}/reports/ordered_products.xlsx?_bearer=${token}`
          },
          ...(account.isEnabled("export_offer_sums")
            ? [
                {
                  id: "offered_amounts",
                  title: "unit.reportDownloads.offered_amounts.title",
                  description:
                    "unit.reportDownloads.offered_amounts.description",
                  url: `/api/v1/projects/${project.slug}/reports/offered_amounts.xlsx?_bearer=${token}`
                }
              ]
            : []),
          {
            id: "ordered_amounts",
            title: "unit.reportDownloads.ordered_amounts.title",
            description: "unit.reportDownloads.ordered_amounts.description",
            url: `/api/v1/projects/${project.slug}/reports/ordered_amounts.xlsx?_bearer=${token}`
          }
        ]
      : []
);
