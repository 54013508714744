import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Growl from "builder_portal/actions/growlActions";
import { Modal, Form, Button } from "semantic-ui-react";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { If } from "shared/components/elements/Conditions";
import { cloneDeep } from "lodash";
import { ProcessDefinitionsResource } from "../../../actions/accountActions";

class ProcessDialog extends Component {
  constructor(props) {
    super(props);
    const model = props.mode === "EDIT" ? cloneDeep(props.model) : {};
    this.state = { open: false, model };
  }

  onClose() {
    this.setState({
      ...this.state,
      open: false,
      model: cloneDeep(this.props.model)
    });
  }

  colseDialog = () => {
    const { mode } = this.props;
    this.setState(prevState => ({
      ...prevState,
      open: false,
      loading: false
    }));
    if (mode === "ADD")
      this.setState(prevState => ({
        ...prevState,
        model: {}
      }));
  };

  onRemove = () => {
    const { model } = this.state;
    const { resource, growl } = this.props;
    return resource
      .remove(model.id)
      .then(() => {
        growl.success(
          `process.messages.remove.success.title`,
          `process.messages.remove.success.body`
        );
        this.colseDialog();
        resource.fetchAll();
      })
      .catch(() => {
        growl.error(
          `process.messages.remove.error.title`,
          `process.messages.remove.error.body`
        );
        this.colseDialog();
      });
  };

  onSave = activityItem => {
    const { mode, growl } = this.props;
    this.setState(prevState => ({ ...prevState, loading: true }));
    const action = mode === "EDIT" ? "edit" : "add";

    return this.props
      .onSave(activityItem, mode)
      .then(() => {
        growl.success(
          `process.messages.${action}.success.title`,
          `process.messages.${action}.success.body`
        );
        this.colseDialog();
      })
      .catch(() => {
        growl.error(
          `process.messages.${action}.error.title`,
          `process.messages.${action}.error.body`
        );
        this.colseDialog();
      });
  };

  baseProcessChanged = (_, { value }) => {
    const { processes } = this.props;

    const process = processes.find(p => p.process_type === value);
    if (process) {
      const { config, activity_scope } = process;

      this.setState(prevState => ({
        ...prevState,
        baseProcess: value,
        model: {
          ...prevState.model,
          config,
          activity_scope,
          existing_process_types: value
        }
      }));
    }
  };

  getProcesses = () => {
    const { processes } = this.props;
    return processes.map(process => ({
      key: process.process_type,
      value: process.process_type,
      text: process.name
    }));
  };

  renderDeleteButton() {
    const { i18n } = this.props;
    const { loading } = this.state;
    const button = (
      <Button
        id="delete"
        color="red"
        basic
        data-form="process_type"
        loading={loading}
        content={i18n["meta.actions.remove"]}
        className="left floated element"
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: this.onRemove
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="process.remove_dialog.title"
        message="process.remove_dialog.message"
        buttons={buttons}
        button={button}
      />
    );
  }

  render() {
    const { i18n, mode } = this.props;
    const { model } = this.state;

    const FormFactory = new FormDefinition({
      fields: [
        {
          id: "process_type",
          label: "process.attributes.process_type.label",
          message: "process.attributes.process_type.error",
          rule: "isRequired"
        },
        {
          id: "name",
          label: "process.attributes.label.label",
          message: "process.attributes.label.error",
          rule: "isRequired"
        },
        {
          id: "activity_scope",
          label: "process.attributes.activity_scope.label",
          message: "process.attributes.activity_scope.error",
          rule: "isRequired"
        },
        {
          id: "deprecated",
          label: "process.attributes.deprecated.label",
          message: "process.attributes.deprecated.error",
          control: "Checkbox"
        },
        {
          id: "startable_from_message_thread",
          label: "process.attributes.startable_from_message_thread.label",
          control: "Checkbox"
        },
        {
          id: "hides_carts",
          label: "process.attributes.hides_carts.label",
          control: "Checkbox"
        },
        {
          id: "existing_process_types",
          label: "process.attributes.existing_process_types.label",
          message: "process.attributes.existing_process_types.error"
        },
        {
          id: "config",
          default: {}
        }
      ]
    });

    const form = FormFactory.create(model, i18n, {
      onChange: model => {
        this.setState({ ...this.state, model });
      }
    });

    form.fields.process_type.props.readOnly = mode === "EDIT";
    form.fields.activity_scope.props.options = [
      {
        value: "unit",
        text: "Wohneinheit"
      },
      {
        value: "section",
        text: "Bauabschnitt"
      },
      {
        value: "project",
        text: "Projekt"
      }
    ];

    if (mode === "ADD") {
      form.fields.existing_process_types.props = {
        ...form.fields.existing_process_types.props,
        options: this.getProcesses(),
        closeOnChange: true,
        onChange: this.baseProcessChanged,
        search: true
      };
    }

    return (
      <Modal
        size="small"
        closeOnEscape={false}
        closeOnDimmerClick={false}
        closeIcon
        trigger={this.props.button}
        open={this.state.open}
        onOpen={() => this.setState({ ...this.state, open: true })}
        onClose={() => this.onClose()}
      >
        <Modal.Header>
          <FormattedMessage
            id="process.title.phase.one"
            default="process.title.phase.one"
          />
        </Modal.Header>
        <Modal.Content>
          <Form id="process_type" data-component="process_type">
            <If condition={mode === "ADD"}>
              <Field
                component="Select"
                {...form.fields.existing_process_types}
              />
            </If>
            <Form.Field>
              <Field component="Input" {...form.fields.process_type} />
            </Form.Field>
            <Form.Field>
              <Field component="Input" {...form.fields.name} />
            </Form.Field>
            <Form.Field>
              <Field component="Select" {...form.fields.activity_scope} />
            </Form.Field>
            <If condition={mode !== "ADD"}>
              <Form.Field>
                <Field component="Checkbox" {...form.fields.deprecated} />
              </Form.Field>
            </If>
            <Form.Field>
              <Field
                component="Checkbox"
                {...form.fields.startable_from_message_thread}
              />
            </Form.Field>
            <Form.Field>
              <Field component="Checkbox" {...form.fields.hides_carts} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <If condition={mode === "EDIT"}>{this.renderDeleteButton()}</If>
          <Button
            id="submit"
            positive
            loading={this.state.loading}
            content={i18n["meta.actions.save"]}
            onClick={form.handleSubmit(this.onSave)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ProcessDialog.propTypes = {
  i18n: PropTypes.object,
  model: PropTypes.object,
  button: PropTypes.object,
  onSave: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  processes: PropTypes.array,
  mode: PropTypes.string.isRequired,
  growl: PropTypes.instanceOf(Growl).isRequired,
  resource: PropTypes.instanceOf(ProcessDefinitionsResource).isRequired
};

ProcessDialog.defaultProps = {
  processes: []
};

const mapDispatchToProps = dispatch => ({
  growl: new Growl(dispatch)
});

export default connect(null, mapDispatchToProps)(ProcessDialog);
