import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Segment } from "semantic-ui-react";
import AttachmentsList from "../attachment/AttachmentsList";
import "./attachmentSegment.scss";

class AttachmentSegment extends React.Component {
  static propTypes = {
    ctxCtrl: PropTypes.object,
    ctxModel: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  render() {
    const { ctxModel, ctxCtrl } = this.props;

    const activity = ctxModel.getActivity();
    const context = {
      account: ctxModel.account,
      activity
    };

    const unitAttachments = ctxModel.getAttachmentsFor(["unit", "project"]);
    const actualAttachments = ctxModel.getExposableAttachments();

    return (
      <Segment.Group data-component="attachmentSegment">
        <Segment>
          <strong>
            <FormattedMessage
              id="attachment.title.other"
              defaultText="attachment.title.other"
            />
          </strong>
        </Segment>
        <Segment loading={this.state.isLoading}>
          <AttachmentsList
            actionLayout="stacked"
            size="small"
            context={context}
            resourceId={Number(activity.id)}
            resourceName="/activities"
            i18n={ctxModel.i18n}
            handleRemove={ctxCtrl.removeAttachment}
            handleUpdate={ctxCtrl.updateAttachment}
            handleAssign={ctxCtrl.assignAttachment}
            handleAdd={ctxCtrl.refresh}
            attachments={actualAttachments}
            parentAttachments={unitAttachments}
            destructionMode="activity"
          />
        </Segment>
        {!actualAttachments.length && (
          <Segment>
            <FormattedMessage
              id="attachment.title.missing"
              defaultText="attachment.title.missing"
            />
          </Segment>
        )}
      </Segment.Group>
    );
  }
}

export default AttachmentSegment;
