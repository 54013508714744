import { createSelector } from "reselect";
import {
  getI18N,
  getPageContent,
  getRouterParams
} from "shared/selectors/base";
import { getAccount } from "shared/selectors/account";
import ActivityModel from "../containers/activity/activityModel";

// eslint-disable-next-line import/prefer-default-export
export const getActivityModel = createSelector(
  [getI18N, getPageContent, getAccount, getRouterParams],
  (i18n, pageContent, account, params) => {
    const { activityId } = params;
    return new ActivityModel(activityId, pageContent, i18n, account);
  }
);
