import { countBy, flatMap, get, keyBy, sumBy, isArray } from "lodash";
import ProductsRepository from "builder_portal/models/roomBook/ProductsRepository";
import { DEFAULT_TAX_RATE } from "../../selectors/projects";
import LineItemBatchAssignment from "./lineItemBatchAssignment";

// returns gross € retail price from net € purchase (1.23) and plain % margin (15) and plain % taxRate (19)
export function calcRetailPrice({ purchasePrice, margin, taxRate }) {
  return +(purchasePrice * (1 + margin / 100) * (1 + taxRate / 100)).toFixed(2);
}

// returns plain % margin (15) from net € purchase (1.23) and gross € retail (4.56) needs plain % taxRate (19)
export function calcMargin({ purchasePrice, retailPrice, taxRate }) {
  const nulldivide = purchasePrice * (taxRate + 100);
  return nulldivide ? Math.round((10000 * retailPrice) / nulldivide - 100) : 0;
}

export default class ActivityModel {
  constructor(id, pageContent, i18n, account) {
    this.id = parseInt(id, 10);
    this.pageContent = pageContent;
    this.i18n = i18n;
    this.account = account;
    this.productRepositories = {};
  }

  findSubLineItem(subLineItemId) {
    const carts = this.getCarts();
    const sections = flatMap(carts, cart => cart.sections);
    const subSections = flatMap(sections, section => section.sections);
    const lineItems = flatMap(subSections, subSection => subSection.line_items);
    const subLineItems = flatMap(
      lineItems,
      lineItem => lineItem.sub_line_items
    );

    return subLineItems.find(subLineItem => subLineItem.id === subLineItemId);
  }

  get(property, defaultValue) {
    return get(this.pageContent, property, defaultValue);
  }

  getActivity() {
    return this.get("activity");
  }

  getContractorAssignments() {
    return get(this.pageContent, "contractor_assignments", []);
  }

  getAssignedContractors() {
    return get(this.pageContent, "contractors", []);
  }

  /*
   * Trades
   */
  getAssignedTrades() {
    const carts = this.getCarts();
    return this.account.getTrades().filter(trade => {
      return carts.some(cart => {
        return cart.trades.indexOf(trade.id) >= 0;
      });
    });
  }

  getAssignedTradesCount() {
    const trades = this.getCarts().reduce((accu, cart) => {
      return accu.concat(cart.trades);
    }, []);

    return countBy(trades);
  }

  getAssigneesByRole() {
    return this.account.getProjectRoles().map(role => {
      const assignees = this.getAvailableAssignees().filter(assignee => {
        return assignee.roles.indexOf(role.id) >= 0;
      });
      return { ...role, assignees };
    });
  }

  getAssignmentInfo() {
    return {
      currentAssignee: this.getCurrentAssignee(),
      assignees: this.getAssigneesByRole(),
      role: this.getRequiredAsssigneeRole(),
      state: this.getCurrentState()
    };
  }

  getAttachments() {
    return this.get("attachments", []);
  }

  getAttachmentsFor(type) {
    if (isArray(type)) {
      return type.reduce(
        (accu, t) => accu.concat(this.getAttachmentsFor(t)),
        []
      );
    }
    return get(this.pageContent, `attachments.${type}`, []);
  }

  getAvailableAssignees() {
    return get(this.pageContent, "available_assignees", []);
  }

  getCarts() {
    return this.get("carts", []);
  }

  getSummaryByTrade() {
    return this.get("summary_by_trade", []);
  }

  getProductRepositoryForPriceCatalog(priceCatalogId) {
    if (!this.productRepositories[priceCatalogId]) {
      this.productRepositories[priceCatalogId] = new ProductsRepository(
        this.getProducts(),
        this.getProductGroups(),
        priceCatalogId
      );
    }
    return this.productRepositories[priceCatalogId];
  }

  getContractorOptions() {
    return {
      contractors: get(this.pageContent, "available_contractors", [])
    };
  }

  getContractorsByTrade(trade) {
    const contractors = keyBy(get(this.pageContent, "contractors", []), "id");
    return this.getContractorAssignments()
      .filter(assignment => {
        return assignment.trade === trade && assignment.contractor_id;
      })
      .map(assignment => {
        return contractors[assignment.contractor_id];
      });
  }

  getCurrentAssignee() {
    const activity = this.getActivity();
    return this.getAvailableAssignees().filter(a => {
      return activity.assignee_id === a.id;
    })[0];
  }

  getCurrentState() {
    const state = this.getStatesById()[this.getStatus()];

    return this.toStateObj(state);
  }

  getExportById(id) {
    return this.getActivity().exports.filter(exp => {
      return exp.id === id;
    })[0];
  }

  /*
   * Exports
   */
  getExportsByRole(role) {
    return this.getActivity().exports.filter(exp => {
      return exp.role === role;
    });
  }

  getExposableAttachments() {
    return this.getAttachmentsFor("activity");
  }

  /*
   * Line Item Batch Assignment
   */
  getLineItemBatchAssignment() {
    const data = get(this.pageContent, "line_item_batch_assignment");

    if (data) {
      return new LineItemBatchAssignment(data, data);
    }
    return null;
  }

  getPriceInquiries() {
    return get(this.getActivity(), "price_inquiries", []);
  }

  // SubLineItems to be retailed
  getRetailSubLineItems() {
    const retailSubLineItems = {};
    const tradeDictionary = this.account.getTradesDictionary();
    const taxRate = this.isShowingTax() ? this.getProjectTaxRate() : 0;

    this.getPriceInquiries().forEach(
      ({
        contractor_id: contractorId,
        items: priceInquiryItems,
        id: priceInquiryId
      }) => {
        priceInquiryItems.forEach(
          ({ room_book_sub_line_item_id: subLineItemId, costs }) => {
            if (!retailSubLineItems[subLineItemId]) {
              const foundSubLineItem = this.findSubLineItem(subLineItemId);
              if (foundSubLineItem) {
                retailSubLineItems[subLineItemId] = foundSubLineItem;
              }
            }

            if (retailSubLineItems[subLineItemId]) {
              const existingRetailPrices =
                retailSubLineItems[subLineItemId].retails || [];
              retailSubLineItems[
                subLineItemId
              ].retails = existingRetailPrices.concat(
                costs
                  .filter(cost => cost.price)
                  .map(
                    ({
                      id: priceInquiryItemCostId,
                      trade: tradeId,
                      price: purchasePrice
                    }) => {
                      const trade = tradeDictionary[tradeId];
                      const tradeLabel = trade?.label;
                      const margin = trade?.defaultMargin || 0;

                      return {
                        contractorId,
                        priceInquiryItemCostId,
                        margin,
                        trade: {
                          tradeLabel,
                          tradeId
                        },
                        taxRate,
                        purchasePrice,
                        retailPrice: calcRetailPrice({
                          margin,
                          purchasePrice,
                          taxRate
                        })
                      };
                    }
                  )
              );
              retailSubLineItems[subLineItemId].price = sumBy(
                retailSubLineItems[subLineItemId].retails,
                "retailPrice"
              );
            } else {
              // Track the data-inconsistency, without breaking the app.
              // eslint-disable-next-line no-console
              console.error(
                `Unable to find referenced subLineItem ${subLineItemId} in priceInquiry ${priceInquiryId}, make sure it is not deleted`
              );
            }
          }
        );
      }
    );

    return retailSubLineItems;
  }

  getProcessDefinition() {
    return this.account.getProcessDefinition(this.getProcessType());
  }

  getProcessType() {
    return this.getActivity().process_type;
  }

  getProductGroups() {
    return this.get("product_groups", []);
  }

  getProducts() {
    return this.get("products", []);
  }

  getProject() {
    return this.get("project");
  }

  getProjectTaxRate() {
    return this.get("project.tax_rate", DEFAULT_TAX_RATE);
  }

  isShowingTax() {
    return !this.get("project.features.show_price_net_label", false);
  }

  getProjectId() {
    return get(this.getProject(), "slug");
  }

  /*
   * Assignee
   */

  getRequiredAsssigneeRole() {
    const currentState = this.getCurrentState();
    return (
      this.account.getProjectRoles().filter(role => {
        return role.id === currentState.assignee_role;
      })[0] || {
        id: currentState.assignee_role,
        label: currentState.assignee_role
      }
    );
  }

  getStateById(id) {
    const state = this.getStatesById()[id];
    return this.toStateObj(state);
  }

  getStates() {
    return this.getProcessDefinition().getStates();
  }

  getStatesById() {
    return this.getProcessDefinition().getStatesById();
  }

  getStatus() {
    return get(this.pageContent, "activity.status");
  }

  getSuccessors() {
    return this.get("successors", []);
  }

  getTrades() {
    return this.account.getTrades();
  }

  /*
   *
   */
  hasMultipleUnits() {
    return this.get("activity.activity_scope", "unit") !== "unit";
  }

  hasProjectProcessDefinition(processType) {
    const pd = this.account.getProcessDefinition(processType);
    return pd && pd.isAvailable(this.getProjectId());
  }

  isForwardMovementTo(nextState) {
    const currentState = this.getStatus();
    const stateIds = this.getStates().map(state => {
      return state.id;
    });

    return stateIds.indexOf(currentState) < stateIds.indexOf(nextState);
  }

  isReady() {
    return this.account && this.getActivity();
  }

  needsReassignment() {
    const currentAssignee = this.getCurrentAssignee();
    const requiredRole = this.getRequiredAsssigneeRole();
    if (!requiredRole.id) {
      return false;
    }
    return get(currentAssignee, "roles", []).indexOf(requiredRole.id) < 0;
  }

  getAccountingMode() {
    return this.account.isNetAccounting() ? "net" : "gross";
  }

  toStateObj(state) {
    return {
      ...state,
      getShortcuts() {
        return get(state, "shortcuts", []);
      },
      hasShortcuts() {
        return this.getShortcuts().length > 0;
      },
      getTransitions() {
        return get(state, "transitions", []);
      },
      getLineItemStatus: () => {
        return get(state, "preconditions.line_item_status", []);
      }
    };
  }
}
