import { flatMap, get, keyBy } from "lodash";
import moment from "moment";
import { Liquid } from "liquidjs";

const liquidEngine = new Liquid({ strictVariables: true });

export default class ProcessDefinition {
  constructor(id, account, i18n) {
    this.id = id;
    this.i18n = i18n;
    this.account = account;
    this.config = get(account.data, "account.config.processes", []).filter(
      process => {
        return process.process_type === id;
      }
    )[0] || {
      id,
      process: []
    };
  }

  getName() {
    return get(this.config, "name", this.id);
  }

  getActivityName() {
    return `${this.getName()} ${moment().format("YYYY-MM-DD")}`;
  }

  getActivityScope() {
    return get(this.config, "activity_scope", false);
  }

  getPhases() {
    return this.config.process.map(phaseValue => {
      return {
        id: phaseValue.id,
        color: phaseValue.color,
        label: phaseValue.label
      };
    });
  }

  getPhaseMap() {
    return keyBy(this.config.process, "id");
  }

  getFlatProcessItems() {
    return this.getStatesById();
  }

  getStateById(id) {
    return this.getStatesById()[id];
  }

  getStatesById() {
    return keyBy(this.getStates(), "id");
  }

  getStates() {
    return get(this.config, "process", []);
  }

  getProcessStartStates() {
    const states = this.getStates();
    const startStates = states.filter(state => {
      return state.startable;
    });
    if (startStates.length > 0) {
      return startStates;
    }
    if (states.length > 0) {
      const firstState = this.config.process[0];
      return firstState ? [firstState] : [];
    }
    return [];
  }

  getStartActions() {
    return flatMap(this.getProcessStartStates(), ({ actions = [], id }) =>
      actions
        .filter(action => action.isStartAction)
        .map(action => ({
          ...action,
          status: id
        }))
    );
  }

  getStartInputs() {
    return flatMap(this.getProcessStartStates(), ({ inputs = {} }) =>
      Object.entries(inputs).map(([name, input]) => ({
        ...input,
        name
      }))
    );
  }

  getDefaultFormValues(parameters) {
    return this.getStartInputs().reduce((accu, { name, template }) => {
      // eslint-disable-next-line no-param-reassign
      accu[name] = liquidEngine.parseAndRenderSync(template, parameters);
      return accu;
    }, {});
  }

  isDeprecated() {
    return get(this.config, "deprecated", false);
  }

  isAvailable(projectId) {
    return !get(this.config, "except_projects", []).includes(projectId);
  }

  useCartCreationDialog() {
    return this.getProcessStartStates().some(
      state => state.useCartCreationDialog
    );
  }

  isAssignableStatus(status) {
    return (
      this.getProcessStartStates()
        .map(s => s.id)
        .indexOf(status) >= 0
    );
  }

  isAssignableToActivity(lineItem, activity) {
    return this.getProcessStartStates().some(state => {
      return (
        activity.status === state.id &&
        get(state, "preconditions.line_item_status", []).indexOf(
          lineItem.status
        ) >= 0 &&
        !ProcessDefinition.buyerSelectionPending(lineItem)
      );
    });
  }

  static buyerSelectionPending(lineItem) {
    // a buyer selection is not considered pending any more, if the status of the line item has progressed
    // as there are cases that buyers change their mind during the offer phase and we shouldn't require them
    // to go to the configurator to change it there.
    return (
      lineItem.status === "open" &&
      lineItem.sub_line_items.some(sli => sli.buyer_selection_pending)
    );
  }

  handlesStatus(lineItemStatus) {
    return (
      !this.isDeprecated() &&
      this.getProcessStartStates().some(state => {
        return (
          get(state, "preconditions.line_item_status", []).indexOf(
            lineItemStatus
          ) >= 0
        );
      })
    );
  }

  shouldPreselect(lineItem) {
    return (
      lineItem.isAssignableToProcess(this) &&
      // TODO: configurable for other processes
      lineItem
        .getSubLineItems()
        .some(sli => sli.selection_is_confirmed || sli.buyer_selection)
    );
  }
}
